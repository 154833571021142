
export const navigationLinks = [
  {
    id: "2",
    name: "about",
    url: "/about",
  },
  {
    id: "3",
    name: "services",
    url: "/services",
  },
  {
    id: "4",
    name: "employers",
    url: "/employers",
  },
  {
    id: "1",
    name: "courses",
    url: "/courses",
  },
  {
    id: "2",
    name: "register course",
    url: "/register-course",
  },
  {
    id: "6",
    name: "contact",
    url: "/contact-us",
  },
];

export const ourServiceLinks = [
  {
    id: "1",
    name: "staffing",
    url: "/staffing",
  },
  {
    id: "2",
    name: "transition planning",
    url: "/transition-planning",
  },
  {
    id: "3",
    name: "treatment plans",
    url: "/treatment-plans",
  },
  {
    id: "4",
    name: "training and development programs",
    url: "/training-and-development",
  },
  {
    id: "5",
    name: "translation services from english to french and from french to english",
    url: "/translation",
  },
];

export const testimonies = [
  {
    id: "0",
    text: " Being in Ghana made me appreciate my name more; made me more confident with who I am, Gayman says. “I understand why my father gave me this name, and I appreciate my West African heritage.”",
    person: " ",
  },
  {
    id: "1",
    text: " We’ve learned about the struggles, but at the castle where they punished the slaves you could feel the suffocation and the pain. That was unexpected,” she says. “I’m not a crier, but I shed a lot of tears that day.”  ",
    person:
      "~ Kennedy Messado, a sophomore psychology major from Kingston, Jamaica",
  },
  {
    id: "2",
    text: "“effortless” conversations with her peers in Ghana. “There was genuine curiosity about what it’s like to go to school in the United States, and we were curious about their institutions and their daily lives.",
    person: "~ Jasmin Blanco-Zapata, a junior from Westchester, New York",
  },
  {
    id: "3",
    text: "She says the trip gave her deeper insight and appreciation for her parents, who grew up in Honduras and who had to readily adjust to an entirely new way of life when they immigrated to the U.S. “This helped me to value their experience,” she says",
    person: "~ Jasmin",
  },
  {
    id: "4",
    text: "“It was an intense experience to learn about the community and their daily lives. They expressed so much joy. I remember thinking that this was my father’s reality, and now I’m seeing the continent of Africa for the first time. Every day offered something mind-blowing.”",
    person: " ",
  },
];
