import { defineType } from "sanity";

export default defineType({
    name: "siteSettings",
    title: 'Site Settings',
    type: 'document',
    fields: [
        {
            name: 'title',
            title: 'Site Title',
            type: 'string',
            initialValue: 'The Outcome Network Inc.'
        },
        {
            name: 'description',
            title: 'Site Description',
            type: 'text',
        },
        {
            name: 'adminEmail',
            title: 'Administrator Email Address',
            type: 'string',
            validation: Rule => Rule.custom(emails => {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (emails) {
                    const emailList = emails.split(',').map(email => email.trim());
                    for (const email of emailList) {
                        if (!emailPattern.test(email)) {
                            return 'One or more email addresses are invalid.';
                        }
                    }
                }
                return true;
            }).error('Please enter valid comma-separated email addresses.')
        },
    ],
})