import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery';
import Popper from 'popper.js';
import { ScrollToProvider } from "./hooks/scrollToContext";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ScrollToProvider>
      <BrowserRouter><App /></BrowserRouter>
    </ScrollToProvider>

  </React.StrictMode>
);
