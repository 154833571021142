import React from "react";
import "./treatment.css";

const TreatmentPlanning = () => {
  return (
    <>
      <div>TreatmentPlanning</div>
    </>
  );
};

export default TreatmentPlanning;
