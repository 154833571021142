import { defineField, defineType } from "sanity";
import { coursesContent, quizQuestion } from "./defaultData";

const courseContent = coursesContent.list[0];

export default defineType({
    name: "course",
    title: "Course",
    type: "object",
    fields: [
        defineField({
            name: "title",
            title: "Title",
            type: "string",
            initialValue: courseContent.title
        }),
        defineField({
            name: "description",
            title: "Description",
            description: "Add a brief description about this course",
            type: "text",
            initialValue: courseContent.description
        }),
        defineField({
            name: "image",
            title: "Image",
            type: "reference",
            to: [{ type: "gallery" }],
        }),
        defineField({
            name: "slug",
            title: "Slug",
            type: "slug",
            options: {
                source: (_, options) => options.parent.title,
                slugify: input => input
                    .toLowerCase()
                    .replace(/\s+/g, '-')
                    .slice(0, 200)
            },
        }),
        // defineField({
        //     name: "videoLink",
        //     title: "Video Link",
        //     description: "Add a link to a video",
        //     type: "url",
        // }),
        defineField({
            name: "lessons",
            title: "Lessons List",
            description: "Add lessons to this course",
            type: "array",
            initialValue: courseContent.lessons,
            of: [{ type: "lesson", }],
        }),

        defineField({
            name: "aboutCourse",
            title: "About Course",
            description: "Add a details about this course",
            type: "object",
            fields: [
                defineField({
                    name: "detailedDescription",
                    title: "Detailed Description",
                    type: "blockContent",
                }),
                defineField({
                    name: "tags",
                    title: "Tags",
                    type: "array",
                    of: [
                        {
                            type: "string",
                        },
                    ],
                }),
            ],
        }),

        defineField({
            name: "quiz",
            title: "Quiz Questions",
            description: "Add quiz questions to this course",
            type: "array",
            of: [{ type: "question", }],
            initialValue: quizQuestion.questions
        }),

        defineField({
            name: "courseInstructor",
            title: "Course Instructor",
            description: "Add a details about the course instructor",
            type: "object",
            initialValue: courseContent.instructor,
            fields: [
                defineField({
                    name: "name",
                    title: "Name",
                    type: "string",
                }),
                defineField({
                    name: "position",
                    title: "Position or Title",
                    type: "string",
                }),
                defineField({
                    name: "bio",
                    title: "Bio Description",
                    type: "text",
                }),
                defineField({
                    name: "image",
                    title: "Profile Image",
                    type: "reference",
                    to: [{ type: "gallery" }],
                }),
                defineField({
                    name: "socialLinks",
                    title: "Social Links",
                    type: "array",
                    of: [
                        {
                            type: "object",
                            fields: [
                                defineField({
                                    name: "name",
                                    title: "Name",
                                    type: "string",
                                }),
                                defineField({
                                    name: "link",
                                    title: "Link",
                                    type: "url",
                                }),
                            ],
                        },
                    ],
                }),
            ],
        }),
    ],

    preview: {
        select: {
            title: "title",
            subtitle: "description",
            media: "image.image",
        }
    },

})