import React from "react";
import { Footer, Navbar, TopButtons } from "../components";
import { Outlet } from "react-router-dom";

const DefaultLayout = () => {
  return (
    <>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <TopButtons />
      <Footer />
    </>
  );
};

export default DefaultLayout;
