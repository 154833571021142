import { defineField, defineType } from "sanity";
import { registerCourseContent } from "./defaultData";

export default defineType({
    name: "registerCourse",
    title: "Register Course Page",
    type: "document",
    fields: [
        defineField({
            name: "headerTitle",
            title: "Header Title",
            type: "string",
            initialValue: registerCourseContent.title,
        }),
        defineField({
            name: "headerDescription",
            title: "Header Description",
            type: "text",
        }),
    ]
})