import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./carousel.css";
import { homeContent } from "../../constants/data";

// import required modules
import { Autoplay, Parallax, Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";

import sanityClient from "../../client.js";
import { urlFor } from "../../utils/urlFor";
import LoadingSpinner from "../LoadingSpinner";

const Carousel = () => {
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "slider"]{..., image->}`)
      .then((data) => {
        setSliderData(data);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("slider data: ", sliderData);
  }, [sliderData]);

  return (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        speed={1500}
        modules={[Autoplay, Parallax, Pagination, Navigation]}
        pagination={{ clickable: true }}
        autoplay={{ delay: 4000 }}
        loop={true}
        scrollbar={{ draggable: false }}
        className=""
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          sliderData?.map((slide, index) => (
            <SwiperSlide key={"slide#" + index + 1}>
              <img
                src={urlFor(slide?.image?.image)}
                className="img-fluid"
                alt={slide?.image?.image?.alt}
              />
              <div className="slider-content">
                <h2>{slide?.title}</h2>
                <p className="text-center">{slide?.subtitle}</p>

                <div className="d-flex align-items-center justify-content-center mt-3 gap-2">
                  {slide?.buttons?.map((button, index) => (
                    <Link
                      key={"slidebtn#" + index}
                      to={button?.link}
                      className={`btn btn-default slide-btn ${
                        index === 1 ? "slide-btn-2" : ""
                      }`}
                    >
                      {button?.name}
                    </Link>
                  ))}
                </div>
              </div>
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </>
  );
};

export default Carousel;
