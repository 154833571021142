import React from "react";
import "./unique.css";

const HowAreWeUnique = () => {
  return (
    <>
      <section className="textContentWrapper">
        <div className="container">
          <div className="header">
            <h1>How are we unique</h1>
          </div>
          <div className="txtBody">
            <article>
              <p>
                You know what you’re looking for better than anyone. That’s why
                we get to know you and your needs and interests. In return, we
                can promise you:
              </p>
              <ul>
                <li>Immediate access to our dedicated specialists</li>
                <li>24/7 support from our team through the entire process</li>
                <li>Access to our network of short- and long-term jobs.</li>
                <li>We can help boost your professional growth.</li>
              </ul>
            </article>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowAreWeUnique;
