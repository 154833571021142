import React from "react";

const YoutubeEmbed = ({ embedId }) => {
  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-center">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
};

export default YoutubeEmbed;
