import React, { useEffect, useRef, useState } from "react";
import {
  GetStartedBanner,
  Header,
  ScrollToTop,
  TwoLineSkeleton,
} from "./../../components";
import "./contact.css";
import { contactDetails } from "../../constants/data";
import { IoLocationOutline } from "react-icons/io5";
import { GrMail } from "react-icons/gr";
import { ImPhone } from "react-icons/im";
import useScrollAnimation from "../../hooks/useScrollAnimation";
import { motion } from "framer-motion";
import {
  fadeInAnimation,
  leftColAnimation,
  rightColAnimation,
  verticalAnimation,
} from "../../utils/Animations";

import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { useEmail } from "../../contexts/EmailContext";

const Contact = () => {
  const initialState = {
    name: "",
    email: "",
    message: "",
    type: "",
    subject: "",
  };

  const [{ name, email, message }, setState] = useState(initialState);

  const [contactData, setContactData] = useState();
  const [loading, setLoading] = useState(true);

  const [textFade, controls1] = useScrollAnimation();
  const [formRef, controls2] = useScrollAnimation();
  const [workRef, controls3] = useScrollAnimation();
  const [mapRef, controls4] = useScrollAnimation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  // const clearState = () => setState({ ...initialState });

  const {
    sendContactFormEmail,
    contactFormRef,
    setFormFields,
    setInitialState,
    statusMessage,
    setStatusMessage,
  } = useEmail();

  useEffect(() => {
    setFormFields({ name, email, message });
    setInitialState(initialState);

    sanityClient
      .fetch(`*[_type == "contact"]`)
      .then((data) => {
        setContactData(data[0]);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  return (
    <div id="contact-us">
      <Header title={contactData?.headerTitle}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="row d-flex justify-content-between mx-auto mt-4">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-center me-md-5">
                <span>
                  <GrMail size={20} color="#fff" />
                </span>
                <a
                  href={`mailto:${contactData?.email}`}
                  className="text-white text-decoration-none ms-2 mb-"
                >
                  {contactData?.email}
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4 pe-lg-0">
              <div className="d-flex align-items-center justify-content-center ms-md-3">
                <ImPhone size={20} color="#fff" />
                <p className="ms-2  mb-0">{contactData?.phone}</p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-center">
                <IoLocationOutline size={20} color="#fff" />
                <p className="ms-2 mb-0">{contactData?.location}</p>
              </div>
            </div>
          </div>
        )}
      </Header>

      {/* caption section */}
      <section className="container my-5">
        <motion.div
          ref={textFade}
          animate={controls1}
          variants={fadeInAnimation}
          transition={{ type: "tween", duration: 0.5 }}
          className="row"
        >
          <div className="col-12 mx-auto">
            <h2 className="fw-bold text-center">
              {contactData?.captionTitle || (
                <Skeleton height={30} width={200} />
              )}
            </h2>
            <p className="mt-3 text-center">
              {contactData?.captionDescription || <TwoLineSkeleton />}
            </p>
          </div>
        </motion.div>
      </section>

      {/* form and work hours */}
      <section className="form-and-hours mb-4">
        <div className="container h-100 d-flex align-items-center ">
          <div className="row w-100 align-items-center">
            {/* form */}
            <motion.div
              ref={formRef}
              variants={leftColAnimation}
              animate={controls2}
              transition={{ type: "tween", duration: 0.6 }}
              className="col-12 col-lg-6 order-2 order-lg-1 ms-3 ms-lg-0"
            >
              <form
                ref={contactFormRef}
                onSubmit={sendContactFormEmail}
                className="w-100 rounded-4 shadow-lg d-flex flex-column justify-content-center px-4 py-5 px-md-5"
              >
                <div className="mb-2 form-group">
                  <label htmlFor="name" className="fw-bold">
                    Full Name *
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Your Full Name"
                    required
                    className="form-control rounded-4 mt-2 py-3 px-4"
                  />
                  <input type="hidden" name="type" value="contact" />
                  <input
                    type="hidden"
                    name="subject"
                    value="Contact Form Submission"
                  />
                </div>
                <div className="mb-2 form-group">
                  <label htmlFor="email" className="fw-bold">
                    Email *
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    required
                    className="form-control rounded-4 mt-2 py-3 px-4"
                  />
                </div>
                <div className="mb-2 form-group">
                  <label htmlFor="message" className="fw-bold">
                    Message *
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="5"
                    placeholder="Your Message"
                    required
                    className="form-control rounded-4 mt-2 py-3 px-4"
                  ></textarea>
                </div>
                <p className="alertMsg mb-2 text-success">{statusMessage}</p>
                <button type="submit" className="btn-dark">
                  Send Message
                </button>
              </form>
            </motion.div>

            {/* hours */}
            <motion.div
              ref={workRef}
              variants={rightColAnimation}
              animate={controls3}
              transition={{ type: "tween", duration: 0.6 }}
              className="col-12 col-lg-6 work-hours order-1 order-lg-2"
            >
              <div className="w-100 rounded-4 d-flex flex-column justify-content-center px-4 py-5 px-md-5 text-white">
                <h2 className="text-center">Our Work Hours</h2>
                <div className="d-flex justify-content-between mt-4">
                  <p className="">Monday - Friday</p>
                  <p className="text-end">8:00 - 17:00</p>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <p className="">Saturday</p>
                  <p className="text-end">9:30 - 17:30</p>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <p className="">Sunday</p>
                  <p className="text-end">9:30 - 15:00</p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* map section */}
      <section>
        <motion.div
          ref={mapRef}
          variants={verticalAnimation}
          animate={controls4}
          transition={{ type: "tween", duration: 0.6 }}
          className="map"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d91283.44626563293!2d-79.74812296184912!3d44.35910963083027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882aa3371ad7206d%3A0x1d2e0f00a84b535a!2sBarrie%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sgh!4v1683553855042!5m2!1sen!2sgh"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Address"
          ></iframe>
        </motion.div>
      </section>

      <GetStartedBanner variant="grey" />
    </div>
  );
};

export default Contact;
