import { defineField, defineType } from "sanity";
import { coursesContent } from "./defaultData";

export default defineType({
    name: "courses",
    title: "Courses Page",
    type: "document",
    fields: [
        defineField({
            name: "headerTitle",
            title: "Header Title",
            type: "string",
            initialValue: coursesContent.title,
        }),
        defineField({
            name: "headerDescription",
            title: "Header Description",
            type: "text",
            initialValue: coursesContent.paragraph,
        }),
        defineField({
            name: "coursesList",
            title: "Courses List",
            type: "array",
            of: [{ type: "course" }],
        }),

    ],
});
