// create the about context
import React, { createContext, useContext, useEffect, useState } from "react";

export const AboutContext = createContext();

export const AboutProvider = ({ children }) => {
  const [aboutData, setAboutData] = useState({});
  const [loadingAbout, setLoadingAbout] = useState(false);

  return (
    <AboutContext.Provider
      value={[aboutData, setAboutData, loadingAbout, setLoadingAbout]}
    >
      {children}
    </AboutContext.Provider>
  );
};

export const useAboutContext = () => useContext(AboutContext);
