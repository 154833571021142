import { defineType, defineField } from "sanity"

export const BUTTONLINKS = [
    { title: 'About', value: '/about' },
    { title: 'Request For Service', value: '/request-for-service' },
    { title: 'Contact', value: '/contact' },
    { title: 'Submit Your Resume', value: '/submit-resume' },
    { title: 'Register Course', value: '/register-course' },
    { title: 'Courses', value: '/courses' },
    { title: 'Employers', value: '/employers' },
    { title: 'Services', value: '/services' },
    { title: 'Home', value: '/' },
]

export default defineType({
    name: 'button',
    title: 'Slider Buttons',
    type: 'object',
    fields: [
        defineField({
            name: 'name',
            title: 'Button Text',
            type: 'string',
            description: 'The text that will appear on the button'
        }),
        defineField({
            name: 'link',
            title: 'Button Link',
            type: 'string',
            description: 'The link that the button will redirect to',
            options: {
                list: BUTTONLINKS
            }
        }),
    ],

    preview: {
        select: {
            name: 'name',
            link: 'link',
        },
        prepare(selection) {
            const { name, link } = selection
            return {
                title: name,
                subtitle: BUTTONLINKS.find((item) => item.value === link)?.title,
            }
        },
    },
})