// define routes referenced in App.js using useRoutes hook
import { Navigate } from "react-router-dom";
import { About, Admin, Career, ClinicalRisk, Contact, CourseDetails, CourseQuiz, Courses, Employers, Home, HowAreWeUnique, Mission, NotFound, OurCulture, RegisterCourse, RequestForService, Services, Staffing, SubmitResume, TrainingAndDevelopment, TransitionPlanning, Translation, TreatmentPlans } from "../pages";
import CourseEnrollment from "../pages/course-enrollment";
import AdminLayout from "../layouts/AdminLayout";
import DefaultLayout from "../layouts/DefaultLayout";

export const routes = [
    {
        path: "/",
        element: <DefaultLayout />,
        children: [
            { path: "/", element: <Home /> },
            { path: "/about", element: <About /> },
            { path: "/contact-us", element: <Contact /> },
            { path: "/career", element: <Career /> },
            { path: "/clinical-risk-process", element: <ClinicalRisk /> },
            { path: "/employers", element: <Employers /> },
            { path: "/how-are-we-unique", element: <HowAreWeUnique /> },
            { path: "/mission", element: <Mission /> },
            { path: "/our-culture", element: <OurCulture /> },
            { path: "/services", element: <Services /> },
            { path: "/staffing", element: <Staffing /> },
            // { path: "/courses", element: <Courses /> },
            {
                path: "/courses/:id", children: [
                    {
                        path: "",
                        element: <CourseDetails />
                    },
                    {
                        path: "quiz",
                        element: <CourseQuiz />
                    }
                ]
            },
            { path: "/register-course", element: <RegisterCourse /> },
            { path: "/enroll-course", element: <CourseEnrollment /> },
            { path: "/training-and-development", element: <TrainingAndDevelopment /> },
            { path: "/transition-planning", element: <TransitionPlanning /> },
            { path: "/translation", element: <Translation /> },
            { path: "/treatment-plans", element: <TreatmentPlans /> },
            { path: "/submit-resume", element: <SubmitResume /> },
            { path: "/request-for-service", element: <RequestForService /> },
            { path: "*", element: <NotFound /> },
        ],
    },
    {
        path: '/admin/*',
        element: (
            // Check if the current URL matches the pattern '/admin/*/*'
            // and redirect to '/admin' if it does
            window.location.pathname.match(/^\/admin\/.*\/.*$/) ? (
                <Navigate to="/admin/" replace />
            ) : (
                <AdminLayout><Admin /> </AdminLayout>
            )
        ),
    },
];