import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { navigationLinks } from "./../../constants/links";
import { Logo } from "./../../assets";
import "./footer.css";
import { useScrollTo } from "../../hooks/scrollToContext";
import { useRefLinks } from "../../hooks/useRefLinks";
import { HiOutlineMail, HiPhone, HiLocationMarker } from "react-icons/hi";
import { useEmail } from "../../contexts/EmailContext";

const Footer = () => {
  const initialState = {
    name: "",
    email: "",
    subject: "",
    phone: "",
    type: "",
    file: {},
    message: "",
  };

  const [formState, setState] = useState(initialState);
  const [error, setError] = useState(false);

  const {
    sendResumeFooterEmail,
    resumeFormFooterRef,
    setFormFields,
    setInitialState,
    statusMessage,
    setStatusMessage,
  } = useEmail();

  const sectionRef = useRef(null);

  const { scrollToSection, setCurrentSection } = useScrollTo();

  const [ourStory, ourServiceLinks] = useRefLinks();

  const handleFileChange = (event) => {
    let file = event.target.files[0];

    if (
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setState({ ...formState, file });
      setError(false);
      setStatusMessage("");
    } else {
      event.target.value = null;
      setState({ ...formState, file: null });
      setError(true);
      setStatusMessage(
        "Invalid file type. Only PDF and Word documents are allowed."
      );
    }
  };

  useEffect(() => {
    // console.log("our Story: ", ourStory);
    setFormFields(formState);
    setInitialState(initialState);
    setStatusMessage("");
  }, []);

  return (
    <>
      <footer>
        <div className="container">
          <div className="formWrapper">
            <div className="header">
              <h1>submit your resume</h1>
              {statusMessage && (
                <p
                  className={`alertMsg mb-2 text-center ${
                    error ? "text-danger" : "text-success"
                  }`}
                >
                  {statusMessage}
                </p>
              )}
            </div>
            <div className="formContainer">
              <form ref={resumeFormFooterRef} onSubmit={sendResumeFooterEmail}>
                <div className="formGroup">
                  <div>
                    <label>Full Name (required)</label>
                    <input type="text" name="name" required />
                    <input
                      type="hidden"
                      name="subject"
                      value="Resume Form Submission"
                    />
                    <input type="hidden" name="type" value="resume-footer" />
                    <input type="hidden" name="message" />
                  </div>
                  <div>
                    <label>Your Email (required)</label>
                    <input type="email" name="email" required />
                  </div>
                </div>
                <div className="formGroup">
                  <div>
                    <label>Phone Number (required)</label>
                    <input type="tel" name="phone" required />
                  </div>
                  <div>
                    <label>Upload You Resume (required)</label>
                    <div className="formGroup fileSubmitGroup">
                      <input
                        type="file"
                        name="file"
                        onChange={handleFileChange}
                        required
                      />
                      <input type="submit" value="Send" className="btn-sea" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="menuContainer">
              <div className="col logoCol">
                <div className="logoWrapper">
                  <img src={Logo} alt="logo" />
                </div>
                <div className="motto">
                  <p>
                    A leader in Staffing Solutions <br /> and Behavior Support
                  </p>
                </div>
              </div>
              <div className="col linksCol">
                <div className="header">
                  <h4>our story</h4>
                </div>
                <div className="permalinks">
                  {ourStory.map((links) => {
                    return (
                      <Link
                        className="story_menu_item"
                        key={links.id}
                        onClick={() => setCurrentSection(links.refname)}
                        to={links.url}
                      >
                        {links.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="col linksCol">
                <div className="header">
                  <h4>services</h4>
                </div>
                <div className="permalinks">
                  {ourServiceLinks.map((links) => {
                    return (
                      <Link
                        className="story_menu_item"
                        key={links.id}
                        onClick={() => setCurrentSection(links.refname)}
                        to={links.url}
                      >
                        {links.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="col linksCol">
                <div className="header">
                  <h4>contact</h4>
                </div>
                <div className="address">
                  <p>
                    <span>The Outcome Network ON, Canada.</span>{" "}
                  </p>
                  <p className="mb-2">
                    <HiOutlineMail size={15} />
                    &nbsp;
                    <span>info@theoutcomenetwork.ca</span>{" "}
                  </p>
                  <p className="mb-2">
                    <HiPhone size={15} />
                    &nbsp;
                    <span>416-995-9105</span>
                  </p>
                  <p className="mb-2">
                    <HiLocationMarker size={15} />
                    &nbsp;
                    <span>Barrie, Ontario</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="footer-menu">
              <div className="copyright">
                <div className="copy-name">
                  &copy;
                  <script>document.write(new Date().getFullYear());</script>
                  {""} <Link to={"./"}> The Outcome Network</Link> <br />
                  <small>Designed & developed by </small> |
                  <a
                    href="http://www.veltetech.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {""} Velte Technology Solutions
                  </a>
                </div>
              </div>
              <div className="menu-items">
                {navigationLinks.map((links) => {
                  return (
                    <Link
                      className="menu_item"
                      key={links.id}
                      to={links.url}
                      onClick={() => setCurrentSection("link" + links.id)}
                    >
                      {links.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
