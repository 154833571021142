import React from "react";
import "./course-enrollment.css";
import { Header } from "../../components";
import { courseEnrollmentContent } from "../../constants/data";
import { banner2 } from "../../assets";
import { useWindowDimensions } from "../../hooks/windowDimensions";

const CourseEnrollment = () => {
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  return (
    <div id="course-enroll">
      <Header
        variant="custom"
        bannerImg={banner2}
        height={windowWidth <= 768 ? "800px" : "700px"}
      >
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-lg-8 text-left head-content">
            <h1 className="fw-light">{courseEnrollmentContent.title}</h1>
            <p className="lead">{courseEnrollmentContent.paragraph}</p>
          </div>
          <div className="col-12 col-lg-4 justify-content-end head-content-2">
            <div className="card border-0 rounded-5 p-4">
              <h3 className="card-title">
                {courseEnrollmentContent.captionTitle}
              </h3>
              <div className="card-text pt-2 pb-4">
                {courseEnrollmentContent.captionParagraph}{" "}
                <a href="#">Privacy Policy.</a>
              </div>
            </div>
          </div>
        </div>
      </Header>

      {/* Form */}
      <section className="container form-section">
        <div className="row mx-auto">
          <div className="col-12 form-container">
            <form className="rounded-4 shadow-lg d-flex flex-column justify-content-center px-4 py-5 px-md-5">
              <div className="d-flex flex-column justify-content-center">
                <h4 className="text-center mb-5">Student Information</h4>
              </div>

              <div className="row mt-2">
                <div className="col-12 col-md-6">
                  {/* email address */}
                  <div className="mb-4 form-group">
                    <label htmlFor="email" className="fw-bold">
                      Email Addres *
                    </label>
                    <input
                      id="email"
                      type="email"
                      placeholder="example@yourmail.com"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>

                  {/* password */}
                  <div className="mb-4 form-group">
                    <label htmlFor="password" className="fw-bold">
                      Password *
                    </label>
                    <input
                      id="password"
                      type="password"
                      placeholder="you password name here"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    />
                  </div>

                  {/* first name */}
                  <div className="mb-4 form-group">
                    <label htmlFor="firstName" className="fw-bold">
                      First Name *
                    </label>
                    <input
                      id="firstName"
                      type="text"
                      placeholder="john"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    />
                  </div>

                  {/* street address */}
                  <div className="mb-4 form-group">
                    <label htmlFor="streetAddress" className="fw-bold">
                      Street Address *
                    </label>
                    <input
                      id="streetAddress"
                      type="text"
                      placeholder="street address here"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    />
                  </div>

                  {/* city */}
                  <div className="mb-4 form-group">
                    <label htmlFor="city" className="fw-bold">
                      City *
                    </label>
                    <input
                      id="city"
                      type="text"
                      placeholder="street address here"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    />
                  </div>
                </div>
                {/* second column */}
                <div className="col-12 col-md-6">
                  {/* confirm email */}
                  <div className="mb-4 form-group">
                    <label htmlFor="confirmEmail" className="fw-bold">
                      Confirm Email Address *
                    </label>
                    <input
                      id="confirmEmail"
                      type="email"
                      placeholder="example@yourmail.com"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    />
                  </div>

                  {/* confirm password */}
                  <div className="mb-4 form-group">
                    <label htmlFor="confirmPassword" className="fw-bold">
                      Confirm Password *
                    </label>
                    <input
                      id="confirmPassword"
                      type="password"
                      placeholder="password"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    />
                  </div>

                  {/* last name */}
                  <div className="mb-4 form-group">
                    <label htmlFor="lastName" className="fw-bold">
                      Last Name *
                    </label>
                    <input
                      id="lastName"
                      type="text"
                      placeholder="doe"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    />
                  </div>

                  <div className="row">
                    <div className="mb-4 col form-group">
                      <label htmlFor="state" className="fw-bold">
                        State *
                      </label>
                      <input
                        id="state"
                        type="text"
                        placeholder="street address here"
                        className="form-control rounded-4 mt-2 py-3 px-4"
                      />
                    </div>
                    {/* zip */}
                    <div className="mb-4 col form-group">
                      <label htmlFor="zip" className="fw-bold">
                        Zip Code *
                      </label>
                      <input
                        id="zip"
                        type="text"
                        placeholder="street address here"
                        className="form-control rounded-4 mt-2 py-3 px-4"
                      />
                    </div>
                  </div>

                  {/* phone number */}
                  <div className="mb-4 form-group">
                    <label htmlFor="phoneNumber" className="fw-bold">
                      Phone Number *
                    </label>
                    <input
                      id="phoneNumber"
                      type="text"
                      placeholder="+1 234 567 8900"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    />
                  </div>
                </div>
              </div>

              <div className="my-4">
                <button className="btn py-3 px-5 btn-default rounded-5 form-btn-dark fw-bold">
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CourseEnrollment;
