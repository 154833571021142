import React from "react";
import "./our_culture.css";

const OurCulture = () => {
  return (
    <>
      <section className="textContentWrapper">
        <div className="container">
          <div className="header">
            <h1>our culture</h1>
          </div>
          <div className="txtBody">
            <article>
              <p>We will foster a positive team environment by:</p>
              <ul>
                <li>Putting ethics above all else</li>
                <li>Treating everyone fairly</li>
                <li>Supporting each other</li>
                <li>Balancing work and life</li>
              </ul>
            </article>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurCulture;
