import { createContext, useContext, useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_URL } from "../utils/constant";

const EmailContext = createContext();

export const EmailProvider = ({ children }) => {
  const resumeFormFooterRef = useRef();
  const resumeFormRef = useRef();
  const employeeFormRef = useRef();
  const trainingFormRef = useRef();
  const certificateRequestFormRef = useRef();
  const contactFormRef = useRef();

  const [{ ...formFields }, setFormFields] = useState({});
  const [initialState, setInitialState] = useState();
  const [statusMessage, setStatusMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const clearState = () => setFormFields({ ...initialState });

  const notifyResume = () => toast.success("Resume Sent Successfully!");

  const notifyEmployee = () =>
    toast.success("Employee Request Sent Successfully!");

  const notifyTraining = () =>
    toast.success("Training Request Sent Successfully!");

  const notifyCertificateRequest = () =>
    toast.success("Certificate Request Sent Successfully!");

  const notifyContactForm = () =>
    toast.success("Message Sent Successfully! We'll get back to you shortly!");

  const sendEmail = (e) => {
    const formData = new FormData(contactFormRef.current);
    console.log("formData: ", formFields);

    console.log("url: ", `${SERVER_URL}/sendMail`);

    axios
      .post(`${SERVER_URL}/sendMail`, formData)
      .then((response) => {
        console.log(response.data);
        e.target.reset();

        notifyContactForm();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const resumeFormSubmit = (e) => {
    const formData = new FormData(resumeFormRef.current);
    console.log("formData: ", formFields);

    console.log("url: ", `${SERVER_URL}/sendMail`);

    axios
      .post(`${SERVER_URL}/sendMail`, formData)
      .then((response) => {
        console.log(response.data);
        e.target.reset();

        notifyResume();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const resumeFormFooterSubmit = (e) => {
    const formData = new FormData(resumeFormFooterRef.current);
    console.log("formData: ", formFields);

    console.log("url: ", `${SERVER_URL}/sendMail`);

    axios
      .post(`${SERVER_URL}/sendMail`, formData)
      .then((response) => {
        console.log(response.data);
        e.target.reset();

        notifyResume();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const employeeFormSubmit = (e) => {
    const formData = new FormData(employeeFormRef.current);
    console.log("formData employee: ", formFields);

    axios
      .post(`${SERVER_URL}/sendMail`, formData)
      .then((response) => {
        console.log(response.data);
        e.target.reset();

        notifyEmployee();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const trainingFormSubmit = (e) => {
    const formData = new FormData(trainingFormRef.current);
    // formData.delete("training_course");
    formData.set("training_course", formFields.training_course);
    console.log("training form: ", formFields);
    axios
      .post(`${SERVER_URL}/sendMail`, formData)
      .then((response) => {
        console.log(response.data);
        e.target.reset();

        notifyTraining();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const certificateRequestFormSubmit = (e) => {
    const formData = new FormData(certificateRequestFormRef.current);
    console.log("cerficate request: ", formFields);
    axios
      .post(`${SERVER_URL}/sendMail`, formData)
      .then((response) => {
        console.log(response.data);
        e.target.reset();

        setFormSubmitted(true);

        notifyCertificateRequest();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendResumeEmail = (e) => {
    e.preventDefault();
    console.log("formFields: ", formFields);

    resumeFormSubmit(e);
  };

  const sendResumeFooterEmail = (e) => {
    e.preventDefault();
    console.log("formFields: ", formFields);

    resumeFormFooterSubmit(e);
  };

  const sendEmployeeEmail = (e) => {
    e.preventDefault();
    console.log("formFields: ", formFields);

    employeeFormSubmit(e);
  };

  const sendTrainingEmail = (e) => {
    e.preventDefault();
    console.log("formFields: ", formFields);

    trainingFormSubmit(e);
  };

  const sendCertificateRequestEmail = (e) => {
    e.preventDefault();
    console.log("formFields: ", formFields);

    certificateRequestFormSubmit(e);
  };

  const sendContactFormEmail = (e) => {
    e.preventDefault();
    console.log("formFields: ", formFields);

    sendEmail(e);
  };

  return (
    <EmailContext.Provider
      value={{
        sendContactFormEmail,
        contactFormRef,
        resumeFormFooterRef,
        resumeFormRef,
        employeeFormRef,
        trainingFormRef,
        setInitialState,
        setFormFields,
        setStatusMessage,
        statusMessage,
        sendResumeEmail,
        sendResumeFooterEmail,
        sendEmployeeEmail,
        sendTrainingEmail,
        sendCertificateRequestEmail,
        certificateRequestFormRef,
        formSubmitted,
        setFormSubmitted,
      }}
    >
      {children}

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </EmailContext.Provider>
  );
};

export const useEmail = () => useContext(EmailContext);
