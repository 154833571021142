import React from "react";
import "./get-started.css";
import { useNavigate } from "react-router-dom";
import { useScrollTo } from "../../hooks/scrollToContext";

const GetStarted = ({ variant = "dark", type = "submit", children }) => {
  const { setCurrentSection } = useScrollTo();

  const navigate = useNavigate();

  return (
    <>
      {/* Get Started */}
      {variant === "dark" && (
        <section id="get-started">
          <div className="container pt-auto pt-md-3">
            <div className="row justify-content-center align-items-center py-5">
              <div className="col-12 col-md-6">
                <h2
                  className="text-md-start text-center"
                  style={{ fontWeight: "500", fontSize: "25px" }}
                >
                  Get Started With Us Now
                </h2>
              </div>

              <div className="col-12 col-md-6 mt-3 mt-md-auto text-center text-md-end">
                {type === "submit" ? (
                  <button
                    className="btn btn-default resume-btn "
                    onClick={() => {
                      navigate("/submit-resume");
                      setCurrentSection("link-submit-resume");
                    }}
                  >
                    Submit Your Resume
                  </button>
                ) : (
                  <button
                    className="btn btn-default resume-btn "
                    onClick={() => {
                      navigate("/request-for-service");
                      setCurrentSection("link-request-service");
                    }}
                  >
                    Request For Service
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {variant === "grey" && (
        <section id="get-started-grey">
          <div className="container my-5 pt-auto pt-md-3">
            <div className="row justify-content-center align-items-center py-5">
              <div className="col-12 col-md-6">
                <h2
                  className="text-md-start text-center"
                  style={{ fontWeight: "500", fontSize: "25px" }}
                >
                  Get Started With Us Now
                </h2>
              </div>

              <div className="col-12 col-md-6 mt-3 mt-md-auto text-center text-md-end">
                {type === "submit" ? (
                  <button
                    onClick={() => {
                      navigate("/submit-resume");
                      setCurrentSection("link-submit-resume-2");
                    }}
                    className="btn btn-default resume-btn-dark "
                  >
                    Submit Your Resume
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      navigate("/request-for-service");
                      setCurrentSection("link-request-service-2");
                    }}
                    className="btn btn-default resume-btn-dark "
                  >
                    Request For Service
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {variant === "custom" && (
        <section id="get-started-custom">
          <div className="container my-5 pt-auto pt-md-3">{children}</div>
        </section>
      )}
    </>
  );
};

export default GetStarted;
