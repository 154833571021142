import { defineField, defineType } from "sanity";

export default defineType({
  name: "about",
  title: "About Page",
  type: "document",
  fields: [
    defineField({
      name: "headerTitle",
      title: "Header Title",
      type: "string",
    }),
    defineField({
      name: "headerDescription",
      title: "Header Description",
      type: "text",
    }),
    defineField({
      name: "missionTitle",
      title: "Mission Title",
      type: "string",
    }),
    defineField({
      name: "missionDescription",
      title: "Mission Description",
      type: "blockContent",
    }),
    defineField({
      name: "missionImage",
      title: "Mission Image",
      type: "reference",
      to: [{ type: "gallery" }],
    }),
    defineField({
      name: "missionList",
      title: "Mission List",
      type: "array",
      of: [{ type: "string" }],
    }),
    defineField({
      name: "uniqueTitle",
      title: "Unique Title",
      type: "string",
    }),
    defineField({
      name: "uniqueDescription",
      title: "Unique Description",
      type: "blockContent",
    }),
    defineField({
      name: "uniqueImages",
      title: "Unique Images",
      type: "array",
      of: [
        {
          name: "uniqueImage",
          type: "reference",
          title: "Unique Image",
          to: [{ type: "gallery" }],
        },
      ],
    }),
    defineField({
      name: "valuesTitle",
      title: "Values Title",
      type: "string",
    }),
    defineField({
      name: "valuesDescription",
      title: "Values Description",
      type: "text",
    }),
    defineField({
      name: "valuesList",
      title: "Values List",
      type: "array",
      of: [{
        type: "object",
        fields: [
          {
            type: "string",
            name: "value",
            title: "Value Text"
          },
          {
            type: "text",
            name: "valueDescription",
            title: "Value Description"
          },
          {
            name: "valueIcon",
            type: "image",
            title: "Value Icon",
          }]
      }],
    }),
    defineField({
      name: "empowermentTitle",
      title: "Empowerment Title",
      type: "string",
    }),
    defineField({
      name: "empowermentDescription",
      title: "Empowerment Description",
      type: "text",
    }),
    defineField({
      name: "empowermentImage",
      title: "Empowerment Image",
      type: "reference",
      to: [{ type: "gallery" }],
    }),
    defineField({
      name: "empowermentVideo",
      title: "Empowerment Video URL",
      type: "url"
    }),
    defineField({
      name: "teamTitle",
      title: "Team Title",
      type: "string",
    }),
    defineField({
      name: "teamDescription",
      title: "Team Description",
      type: "text",
    }),
    defineField({
      name: "teamMembers",
      title: "Team Members",
      type: "array",
      of: [
        {
          name: "teamMember",
          type: "object",
          title: "Team Member",
          fields: [
            {
              name: "name",
              type: "string",
              title: "Name"
            },
            {
              name: "title",
              type: "string",
              title: "Title"
            },
            {
              name: "image",
              type: "reference",
              title: "Image",
              to: [{ type: "gallery" }],
            },
          ]
        }
      ]
    }),
  ],

  // preview: {
  //   select: {
  //     title: 'title',
  //     author: 'author.name',
  //     media: 'mainImage',
  //   },
  //   prepare(selection) {
  //     const {author} = selection
  //     return {...selection, subtitle: author && `by ${author}`}
  //   },
  // },
});
