import { defineField, defineType } from "sanity";
import { contactPageContent } from "./defaultData";

export default defineType({
    name: "contact",
    title: "Contact Page",
    type: "document",
    fields: [
        defineField({
            name: "headerTitle",
            title: "Header Title",
            type: "string",
            initialValue: contactPageContent.title,
        }),
        defineField({
            name: "email",
            title: "Email",
            type: "string",
            initialValue: contactPageContent.email,
        }),
        defineField({
            name: "phone",
            title: "Phone",
            type: "string",
            initialValue: contactPageContent.phone,
        }),
        defineField({
            name: "location",
            title: "Location",
            type: "string",
            initialValue: contactPageContent.location,
        }),
        defineField({
            name: "captionTitle",
            title: "Caption Title",
            description: "Caption under the header",
            type: "string",
            initialValue: contactPageContent.caption,
        }),
        defineField({
            name: "captionDescription",
            title: "Caption Description",
            type: "text",
            initialValue: contactPageContent.paragraph,
        }),
    ]
})