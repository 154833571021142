import { defineType } from "sanity";
import { BiCarousel } from "react-icons/bi"
import ArrayMaxItems from "../components/ArrayMaxItems";

export default defineType({
    name: 'slider',
    title: 'Sliders',
    type: 'document',
    icon: BiCarousel,
    fields: [
        {
            name: 'title',
            title: 'Slider Title',
            type: 'string',
            description: 'The title that will appear on the slider'
        },
        {
            name: 'subtitle',
            title: 'Slider Subtitle',
            type: 'text',
            description: 'The subtitle that will appear on the slider'
        },
        {
            name: 'buttons',
            title: 'Slider Buttons',
            type: 'array',
            components: { input: ArrayMaxItems },
            of: [{ type: 'button' }],
            description: 'The buttons that will appear on the slider',
            validation: Rule => Rule.max(2).warning('You can only have 2 buttons')
        },
        {
            name: 'image',
            title: 'Slider Image',
            type: 'reference',
            description: 'The image that will appear on the slider',
            to: [{ type: 'gallery' }]
        },
    ]
})