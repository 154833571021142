import { defineField, defineType } from "sanity";
import { employersContent } from "./defaultData";

export default defineType({
    name: "employers",
    title: "Employers Page",
    type: "document",
    fields: [
        defineField({
            name: "headerTitle",
            title: "Header Title",
            type: "string",
            initialValue: employersContent.title,
        }),
        defineField({
            name: "headerDescription",
            title: "Header Description",
            type: "text",
            initialValue: employersContent.paragraph,
        }),
    ],
});
