import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { banner2 } from "../../assets";
import { useWindowDimensions } from "../../hooks/windowDimensions";
import { trainingContent } from "../../constants/data";
import "./register-course.css";
import { motion } from "framer-motion";
import sanityClient from "../../client";
import { useEmail } from "../../contexts/EmailContext";

const RegisterCourse = () => {
  const initialState = {
    name: "",
    email: "",
    subject: "",
    phone: "",
    type: "training",
    training_date: "",
    notes: "",
    course_link: "",
  };

  const [formState, setState] = useState(initialState);
  const [error, setError] = useState(false);
  const [registerCourseData, setRegisterCourseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coursesData, setCoursesData] = useState();
  const [courseLinkGenerated, setCourseLinkGenerated] = useState("");

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const {
    sendTrainingEmail,
    trainingFormRef,
    setFormFields,
    setInitialState,
    statusMessage,
    setStatusMessage,
  } = useEmail();

  const descriptionExists = () =>
    registerCourseData?.hasOwnProperty("headerDescription");

  const handleTrainingCourseChange = (event) => {
    let training_course = event.target.value;
    console.log("course selected: ", training_course);

    if (training_course !== "select") {
      let courseLink = generateCourseLink(event.target.value);
      setCourseLinkGenerated(courseLink);
      console.log("course link generated: ", courseLink);

      setState({
        ...formState,
        course_link: courseLink,
      });
      console.log("form state: ", formState);
      setError(false);
      setStatusMessage("");
    } else {
      event.target.value = null;
      setState({ ...formState, training_course: null });
      setError(true);
      setStatusMessage("Please select a training course.");
    }
  };

  const handleDateChange = (event) => {
    console.log("date selected: ", event.target.value);
    setState({ ...formState, training_date: event.target.value });
  };

  const generateCourseLink = (course) => {
    let baseLink = window.location.href;
    baseLink = baseLink.replace("/register-course", "/courses/");
    let courseLink = baseLink + course;

    return courseLink;
  };

  useEffect(() => {
    // console.log("our Story: ", ourStory);
    setFormFields(formState);
    setInitialState(initialState);
    setStatusMessage("");

    sanityClient
      .fetch(`*[_type == "registerCourse"]`)
      .then((data) => {
        setRegisterCourseData(data[0]);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    setLoading(true);
    sanityClient
      .fetch(
        `*[_type == "courses"]{..., coursesList[] { ..., 'slug': slug.current, image->, courseInstructor {..., image->}} }`
      )
      .then((data) => {
        setCoursesData(data[0]);
        setLoading(false);
      })
      .catch(console.error);
  }, [registerCourseData]);

  useEffect(() => {
    console.log("register course data: ", registerCourseData);
    console.log("description exists: ", descriptionExists());
  }, [registerCourseData]);

  return (
    <div id="register-course">
      <Header
        title={registerCourseData?.headerTitle}
        bannerImg={banner2}
        height={windowWidth <= 991 ? "400px" : "600px"}
      >
        {descriptionExists() ? (
          <p className="mt-3">
            {registerCourseData?.headerDescription || "Loading..."}
          </p>
        ) : (
          loading && <p>Loading...</p>
        )}
      </Header>

      {/* register form */}
      <motion.section
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "tween", duration: 0.7 }}
        className="container form-section"
      >
        <div className="row mx-auto">
          <div className="col-12 form-container">
            <form
              className="rounded-4 shadow-lg d-flex flex-column justify-content-center px-4 py-5 px-md-5"
              ref={trainingFormRef}
              onSubmit={sendTrainingEmail}
            >
              <div className="d-flex flex-column justify-content-center">
                <h4 className="text-center mb-5">Register for this Training</h4>
                {statusMessage && (
                  <p
                    className={`alertMsg mb-2 text-center ${
                      error ? "text-danger" : "text-success"
                    }`}
                  >
                    {statusMessage}
                  </p>
                )}
              </div>

              <div className="row mt-2">
                <div className="col-12 col-md-6">
                  {/* full name */}
                  <div className="mb-4 form-group">
                    <label htmlFor="fullName" className="fw-bold">
                      Full Name *
                    </label>
                    <input
                      id="fullName"
                      type="text"
                      placeholder="full name"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      name="name"
                      required
                    />
                    <input
                      type="hidden"
                      name="subject"
                      value="Training Form Submission"
                    />
                    <input type="hidden" name="type" value="training" />
                    <input
                      type="hidden"
                      name="course_link"
                      value={courseLinkGenerated}
                    />
                  </div>

                  {/* phone */}
                  <div className="mb-4 form-group">
                    <label htmlFor="phone" className="fw-bold">
                      Phone Number *
                    </label>
                    <input
                      id="phone"
                      type="tel"
                      placeholder="+1 234 567 890"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      name="phone"
                      required
                    />
                  </div>
                </div>
                {/* second column */}
                <div className="col-12 col-md-6">
                  {/* email */}
                  <div className="mb-4 form-group">
                    <label htmlFor="email" className="fw-bold">
                      Your Email *
                    </label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="example@yourmail.com"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>

                  {/* training */}
                  <div className="mb-4 form-group">
                    <label htmlFor="trainingCourse" className="fw-bold">
                      Training *
                    </label>
                    <select
                      id="trainingCourse"
                      className="form-select mt-2 py-3 px-4 rounded-4"
                      aria-label="Default select example"
                      onChange={handleTrainingCourseChange}
                      required
                      name="ttt"
                    >
                      <option>select</option>
                      {coursesData?.coursesList?.map((item, index) => (
                        <option key={index + item._key} value={item.slug}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* full column */}
                <div className="col-12">
                  {/* date */}
                  <div className="mb-4 form-group">
                    <label htmlFor="courseDate" className="fw-bold">
                      Course Date *
                    </label>
                    <input
                      id="courseDate"
                      type="date"
                      name="training_date"
                      placeholder="mm/dd/yyyy"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      onChange={handleDateChange}
                      required
                    />
                  </div>
                </div>
                {/* duties and responsibilities */}
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="additionalNotes" className="fw-bold">
                      Additional Notes *
                    </label>
                    <textarea
                      name="notes"
                      id="additionalNotes"
                      cols="30"
                      rows="8"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="my-4">
                <input
                  type="submit"
                  value="SEND"
                  className="btn py-3 px-5 btn-default rounded-5 form-btn-dark fw-bold"
                ></input>
              </div>
            </form>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default RegisterCourse;
