import React from "react";
import "./mission.css";

const Mission = () => {
  return (
    <>
      <section className="textContentWrapper">
        <div className="container">
          <div className="header">
            <h1>our mission</h1>
          </div>
          <div className="txtBody">
            <article>
              <p>
                Our mission is to be the staffing provider and employer of
                choice that direct support professionals and service providers
                consider a vital partner in achieving their goals.
              </p>
            </article>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;
