import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import { Cards, Carousel, TwoLineSkeleton } from "../../components";
import Img from "../../components/Img";
import { useScrollTo } from "../../hooks/scrollToContext";
import { urlFor } from "../../utils/urlFor";
import "./home.css";
import { useAboutContext } from "../../contexts/AboutContext";

const Home = () => {
  const [homeData, setHomeData] = useState({});
  const [loading, setLoading] = useState(true);

  const { setCurrentSection } = useScrollTo();
  const { setAboutData } = useAboutContext();

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "home"]`)
      .then((data) => {
        setHomeData(data[0]);
        setLoading(false);
      })
      .catch(console.error);

    sanityClient
      .fetch(
        `*[_type == "about"]{..., missionImage->, uniqueImages[]->, empowermentImage->, teamMembers[] {..., image->}}`
      )
      .then((data) => {
        console.log("data about: ", data[0]);
        setAboutData(data[0]);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("home data: ", homeData);
  }, [homeData]);

  return (
    <>
      <section className="hero">
        <Carousel />
      </section>

      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 col left">
              <div className="txtContent">
                <div className="header">
                  <h1>
                    {homeData?.aboutTitle || (
                      <Skeleton height={45} width={200} />
                    )}
                  </h1>
                </div>
                <div className="txtDesc">
                  <h4>
                    {homeData?.captionText || (
                      <Skeleton height={40} width={350} />
                    )}
                  </h4>
                  <p>
                    {homeData?.aboutDescription || (
                      <Skeleton width={"100%"} count={4} />
                    )}
                  </p>
                </div>
              </div>

              <div className="d-md-block d-none">
                <Link
                  to={"./about"}
                  onClick={() => setCurrentSection("link-about")}
                  className="btn-dark"
                >
                  read more
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12 col right mt-0 mt-lg-5">
              <div className="aboutIllustr d-none d-lg-block">
                <div className="backdrop seaBlue" />
                <div className="backdrop darkBlue" />
                <Img
                  src={urlFor(homeData?.aboutImage)}
                  hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                  height={298}
                  width={400}
                  alt={"nurse and patient in wheel chair"}
                />
              </div>
              <div className="d-block d-lg-none">
                <Img
                  src={urlFor(homeData?.aboutImage)}
                  hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                  height={298}
                  width={400}
                  alt={"nurse and patient in wheel chair"}
                />
              </div>
            </div>
          </div>

          <div className="row mt-5 mt-md-auto d-md-none d-block">
            <div className="col-md-6 col-12 p text-center text-md-start">
              <Link
                to={"./about"}
                onClick={() => setCurrentSection("link-about")}
                className="btn-dark"
              >
                read more
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="services">
        <div className="container">
          <div className="top">
            <div className="header pt-5">
              <h1>
                {homeData?.serviceTitle || <Skeleton height={45} width={200} />}
              </h1>
            </div>
            <div className="d-flex flex-column justify-content-center w-100  px-md-5">
              <p className="text-center">
                {homeData?.serviceDescription || <TwoLineSkeleton />}
              </p>
              <Link
                to={"./request-for-service"}
                onClick={() => setCurrentSection("link-request-for-service")}
                className="btn-dark mx-auto"
              >
                request for service
              </Link>
            </div>
          </div>
          <div className="bottom">
            <Cards />
            <Link
              to={"./services"}
              onClick={() => setCurrentSection("link-services")}
              className="btn-dark"
            >
              read more
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
