import { useAnimationControls } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const useScrollAnimation = (threshold = 0.2) => {
    const controls = useAnimationControls();
    const { ref, inView, entry } = useInView({ threshold });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
            console.log("inView: ", inView, "\n entry; ", entry);
        } else {
            controls.start("hidden");
        }
    }, [ref, inView]);

    return [ref, controls];
}

export default useScrollAnimation;
