import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./top_buttons.css";
import { FiSettings } from "react-icons/fi";
import { MdOutlineArticle, MdSlowMotionVideo } from "react-icons/md";
import ModalVideo from "react-modal-video";
import sanityClient from "../../client";

const TopButtons = () => {
  const [isOpen, setOpen] = useState(false);
  const [empowermentVideo, setEmpowermentVideo] = useState();

  const checkOut = () => {
    console.log("checkOut");
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"]{..., missionImage->, uniqueImages[]->, empowermentImage->, teamMembers[] {..., image->}}`
      )
      .then((data) => {
        setEmpowermentVideo(data[0]?.empowermentVideo);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <div className="float-buttons">
        <ul className="">
          <li>
            <Link
              onClick={() => setOpen(true)}
              className="btn btn-default video-btn d-flex align-items-center  justify-content-between "
            >
              <span className="pe-2">checkout our work</span>
              <span className="ms-1">
                <MdSlowMotionVideo size={20} className="rotating" />
              </span>
            </Link>
          </li>
          <li className="">
            <Link
              to={"./request-for-service"}
              className="btn btn-default request-btn-2 d-flex align-items-center justify-content-between "
            >
              <span className="">request for service</span>
              <span className="ms-1">
                <FiSettings size={20} className="rotating" />
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={"./submit-resume"}
              className="btn btn-default resume-btn-2 d-flex align-items-center  justify-content-between "
            >
              <span className="pe-2">submit your resume</span>
              <span className="ms-1" style={{ fontSize: "1rem" }}>
                <MdOutlineArticle size={20} />
              </span>
            </Link>
          </li>
        </ul>
      </div>

      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        url={empowermentVideo}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default TopButtons;
