import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { coursesContent } from "../../constants/data";
import {
  Header,
  InstructorSkeleton,
  VideoSkeleton,
  LessonItemSkeleton,
  YoutubeEmbed,
  PDFViewer,
} from "../../components";
import { Player } from "video-react";
import "./course-details.css";
import { BsPlay } from "react-icons/bs";
import { ImFacebook, ImTwitter, ImLinkedin2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import sanityClient from "../../client";
import { urlFor } from "../../utils/urlFor";
import BlockContent from "@sanity/block-content-to-react";
import Skeleton from "react-loading-skeleton";
import codeOfEthicsPdf from "../../assets/Living The Code Of Ethics.pdf";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useScrollTo } from "../../hooks/scrollToContext";

const CourseDetails = () => {
  const params = useParams();
  const [currentLesson, setCurrentLesson] = useState();
  const [coursesData, setCoursesData] = useState();
  const [courseDetail, setCourseDetail] = useState();
  const [loading, setLoading] = useState(true);
  const [showCodeOfEthics, setShowCodeOfEthics] = useState(false);

  const navigate = useNavigate();

  const { setCurrentSection } = useScrollTo();

  const handleSelectCourse = (lesson) => {
    console.log("selected course: ", lesson);
    // navigate("/courses/" + lesson.id);
    setCurrentLesson(lesson);
  };

  const getEmbedId = (url) => {
    let embedId = url?.split("v=")[1];
    return embedId;
  };

  const isCodeOfEthics = () => {
    return courseDetail?.title
      ?.toLowerCase()
      .includes("the outcome code of ethics");
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "courses"]{..., coursesList[] { ..., 'slug': slug.current, image->, courseInstructor {..., image->}} }`
      )
      .then((data) => {
        setCoursesData(data[0]);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("course data: ", coursesData);
    let details = coursesData?.coursesList?.find(
      (course) => course.slug === params.id
    );
    console.log("details: ", details);
    setCourseDetail(details);
  }, [coursesData]);

  useEffect(() => {
    setCurrentLesson(courseDetail?.lessons[0]);
    console.log("current lesson: ", currentLesson);
    console.log("course detail: ", courseDetail);
  }, [courseDetail]);

  return (
    <div className="course-detail">
      <Header title={courseDetail?.title}>
        <p>{courseDetail?.description || "Loading..."}</p>
      </Header>

      {/* Video Section */}
      {!isCodeOfEthics() && (
        <section className="container mt-4">
          {loading && <VideoSkeleton />}
          <YoutubeEmbed embedId={getEmbedId(currentLesson?.videoLink)} />
        </section>
      )}

      {/* Course details */}
      <section className="container mt-3 mb-4">
        <div className="row mt-3">
          {!isCodeOfEthics() ? (
            <div className="col-12 course-content">
              {/* course name */}
              <div className="d-flex justify-content-between align-items-center">
                <h2>{currentLesson?.title || <Skeleton width={400} />}</h2>

                {/* <button
                  className="btn btn-default resume-btn-dark"
                  onClick={() => navigate("/enroll-course")}
                >
                  Enroll Now
                </button> */}
              </div>

              {/* course description */}
              <div>
                <p className="mt-3">
                  {courseDetail?.description || (
                    <Skeleton count={4} style={{ marginBottom: "5px" }} />
                  )}
                </p>
              </div>

              {/* QAM */}
              <div className="d-flex flex-column">
                <div>
                  <h5 className="bg-dark-blue py-3 px-3">QAM</h5>
                </div>

                {/* course list */}
                <ul className="list-group">
                  {loading && <LessonItemSkeleton items={5} />}
                  {courseDetail?.lessons.map((lesson, index) => (
                    <li
                      key={index}
                      className={`list-group-item py-3 lesson ${
                        lesson.title === currentLesson?.title && "active"
                      }`}
                      onClick={() => handleSelectCourse(lesson)}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-75">
                          <h6 className="my-auto">
                            <BsPlay size={20} /> <span>{lesson.title}</span>
                          </h6>
                        </div>
                        <div className="w-25">
                          <p className="text-end my-auto">
                            {index + 1} of {courseDetail?.lessons.length}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {/* about the course */}
              <div className="mt-3">
                <h5 className="bg-dark-blue py-3 px-3">About this Course</h5>
                <div className="mt-3 mb-2">
                  {loading && <Skeleton count={8} />}
                  <BlockContent
                    blocks={courseDetail?.aboutCourse?.detailedDescription}
                  />
                </div>

                <ol type="01" className="">
                  {/* {courseDetail?.aboutCourse?.list.map((item, index) => (
                  <li key={item + "#" + index} className="py-1">
                    {item}
                  </li>
                ))} */}
                </ol>
              </div>

              {/* tags */}
              <div className="mt-2">
                <div className="d-flex flex-wrap">
                  {courseDetail?.aboutCourse.tags.map((tag, index) => (
                    <span key={tag + "#" + index} className="tag me-2 mb-2">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>

              {/* instructor */}
              <div className="mt-3">
                <h5 className="bg-dark-blue py-3 px-3">Course Instructor</h5>
                {loading && <InstructorSkeleton />}
                <div className="bg-grey px-3 py-4 d-flex justify-content-start align-items-center">
                  <img
                    src={urlFor(courseDetail?.courseInstructor?.image?.image)}
                    className="instructor-img"
                    alt={courseDetail?.courseInstructor?.image?.image?.alt}
                  />
                  <div className="ms-3">
                    <h5 className="mb-0">
                      {courseDetail?.courseInstructor?.name}
                    </h5>
                    <small>{courseDetail?.courseInstructor?.position}</small>

                    <div>
                      <p className="mb-2">
                        {courseDetail?.courseInstructor?.bio}
                      </p>
                    </div>

                    <div className="d-flex flex-wrap">
                      {courseDetail?.courseInstructor?.socialLinks?.map(
                        (link, index) => (
                          <>
                            {link.name === "facebook" && (
                              <a
                                key={index}
                                href={link.link}
                                target="_blank"
                                className="me-3"
                              >
                                <ImFacebook size={15} color="#0C031B" />
                              </a>
                            )}
                            {link.name === "twitter" && (
                              <a
                                key={index}
                                href={link.link}
                                target="_blank"
                                className="me-3"
                              >
                                <ImTwitter size={15} color="#0C031B" />
                              </a>
                            )}
                            {link.name === "linkedin" && (
                              <a
                                key={index}
                                href={link.link}
                                target="_blank"
                                className="me-3"
                              >
                                <ImLinkedin2 size={15} color="#0C031B" />
                              </a>
                            )}
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-12">
              {/* <object
                data={codeOfEthicsPdf}
                type="application/pdf"
                width="100%"
                height="1000px"
              ></object> */}
              <PDFViewer />
            </div>
          )}

          {/* call to action */}
          {courseDetail?.quiz[0].question && (
            <div className="my-4 text-center">
              <h5 className="py-3 px-3">
                Ready to take the quiz? Click the button below to get started.
              </h5>
              <button
                className="btn btn-default resume-btn-dark"
                onClick={() => {
                  navigate("/courses/" + params.id + "/quiz");
                  setCurrentSection("link-take-quiz");
                }}
              >
                Take Quiz
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default CourseDetails;
