import React from "react";
import Skeleton from "react-loading-skeleton";

const VideoSkeleton = () => {
  return (
    <div className="px-5">
      <Skeleton
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "0 auto",
          height: "480px",
        }}
      />
    </div>
  );
};

export default VideoSkeleton;
