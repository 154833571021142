export const servicesContent = {
    title: "Our Services",
    paragraph:
        "We are dedicated to support the person experiencing exceptional needs and their families through a Staffing that displays the highest level of ethics, exceptional customer service and honor all commitments.",
    section1: {
        title: "Staffing",
        // paragraph:
        //     [
        //         {
        //             "_type": "span",
        //             "text": "We are dedicated to support the person experiencing exceptional needs and their families through a Staffing that displays the highest level of ethics, exceptional customer service and honor all commitments.",
        //             "marks": []
        //         }
        //     ],
    },
    section2: {
        title: "Transition Planning",
        // paragraph:
        //     [
        //         {
        //             "_type": "span",
        //             "text": "Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.",
        //             "marks": []
        //         }
        //     ]
    },
    section3: {
        title: "Clinical Risk Process",
        // paragraph:
        //     [
        //         {
        //             "_type": "span",
        //             "text": "Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.",
        //             "marks": []
        //         }
        //     ],
    },
    section4: {
        title: "Treatment Plans",

    },
    section5: {
        title: "Training and Development Programs",

    },
    section6: {
        title: "Translation Services",

    },
};

export const employersContent = {
    title: "Our Service Providers",
    paragraph:
        "The Outcome Network will provide you with quality staff and lower costs for everyone. We understand that staffing is about more than just filling a position. Our focus is to actively recruit and carefully screen direct support professionals to provide the trusted network of the best healthcare talent when your need arises. ",

}

export const coursesContent = {
    title: "Courses",
    paragraph:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    list: [
        {
            title: "Course Lorem Ipsum Title",
            description:
                "In this course you will learn how to create a beautiful website with Felix. This course is designed for the absolute beginner, meaning no experience with Felix is required. You will start by learning how to plan your website by creating a site map and gathering content.",
            lessons: [
                {
                    title: "Lesson 1",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 2",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 3",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 4",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 5",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 6",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
            ],
            aboutCourse: {
                detailedDescription:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
                tags: ["Analyze", "Design", "Develop", "Implement", "Evaluate"],
            },
            instructor: {
                name: "Caroline Parker",
                bio:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s.",
                position: "Marketing Leader",
                socialLinks: [
                    {
                        _key: "facebookId",
                        name: "facebook",
                        link: "https://www.facebook.com/",
                    },
                    {
                        _key: "twitterId",
                        name: "twitter",
                        link: "https://twitter.com/",
                    },
                    {
                        _key: "linkedInId",
                        name: "linkedin",
                        link: "https://www.linkedin.com/",
                    },
                ],
            },
        },
        {
            title: "Course Lorem Ipsum Title",
            description:
                "In this course you will learn how to create a beautiful website with Felix. This course is designed for the absolute beginner, meaning no experience with Felix is required. You will start by learning how to plan your website by creating a site map and gathering content.",
            lessons: [
                {
                    title: "Lesson 1",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 2",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 3",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 4",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 5",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
                {
                    title: "Lesson 6",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
                },
            ],
            aboutCourse: {
                detailedDescription:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
                tags: ["Analyze", "Design", "Develop", "Implement", "Evaluate"],
            },
            instructor: {
                name: "John Doe",
                bio:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s.",
                position: "Professor",
                socialLinks: [
                    {
                        name: "facebook",
                        link: "https://www.facebook.com/",
                    },
                    {
                        name: "twitter",
                        link: "https://twitter.com/",
                    },
                    {
                        name: "linkedin",
                        link: "https://www.linkedin.com/",
                    },
                ],
            },
        },
    ]
}

export const registerCourseContent = {
    title: "The OutCome Network Training Registration",
}

export const requestForServiceContent = {
    title: "Request an employee",
    paragraph:
        "Are you looking for an outstanding staff? Please complete the form below or contact us directly:",
}

export const submitResumeContent = {
    title: "Submit your resume",
}

export const contactPageContent = {
    title: "Contact Us",
    email: "info@theoutcomenetwork.ca",
    phone: "(+1) 416-995-9105",
    location: "Barrie Ontario",

    caption: "How Can We Help You?",
    paragraph:
        "Please fill out the form drop your inquiry and a member of our team will reach out shortly.",
}

export const quizQuestion = {
    answers: [
        "True",
        "False",
    ],
    answerOptions: [
        {
            title: "True",
            value: 1,
        },
        {
            title: "False",
            value: 2,
        }
    ],
    questions: [
        {
            question: `“abuse” means action or behaviour that causes or is likely to cause physical injury or psychological harm or both to a person with a developmental disability, or results or is likely to result in significant loss or destruction of their property, and includes neglect; (“mauvais traitements”)`,
            correctAnswer: 1,
        },
        {
            question: `“neglect” means the failure to provide a person with a developmental disability with the support and assistance that is required for their health, safety or well-being and includes inaction or a pattern of inaction that jeopardizes the health or safety of the person; (“négligence”)`,
            correctAnswer: 1,
        },
        {
            question: `abuse includes any and all of physical, sexual, emotional, verbal and financial abuse.`,
            correctAnswer: 1,
        },
        {
            question: `Every service agency shall address quality assurance measures in Safety and security of persons with developmental disabilities.`,
            correctAnswer: 1,
        },
        {
            question: `Every service agency shall not address quality assurance measures in the assistance with the management of finances.`,
            correctAnswer: 2,
        },
        {
            question: `Every service agency shall not address quality assurance measures in the development of individual support plans.`,
            correctAnswer: 2,
        },
        {
            question: `Every service agency shall address quality assurance measures in promoting Confidentiality and privacy.`,
            correctAnswer: 1,
        },
        {
            question: `All service agency shall ensure that the person with a developmental disability is supported to participate as fully as possible in the development and annual review of their individual support plan.`,
            correctAnswer: 1,
        },
        {
            question: `In addressing quality assurance measures respecting abuse prevention and reporting, each service agency shall,
            (a) provide,
            (i) mandatory training on abuse prevention, identification and reporting to all of its staff members and volunteers who have direct contact with persons with developmental disabilities who are receiving services and supports, and
            (ii) a refresher course on the matters referred to in subclause (i) every year thereafter;`,
            correctAnswer: 1,
        },
        {
            question: `All service agency shall have policies and procedures on the notification of persons acting on behalf of the person with a developmental disability of an alleged, suspected or witnessed incident of abuse`,
            correctAnswer: 1,
        }
    ]
}