import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../Navbar/navbar.css";
import { Logo } from "../../assets";
import { navigationLinks } from "./../../constants/links";
import { FaAlignRight } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import TopButtons from "../topButtons";
import { AiOutlineCaretDown } from "react-icons/ai";
import { useScrollTo } from "../../hooks/scrollToContext";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [isNavToggle, setIsNavToggle] = useState(false);
  const [userScrolling, setUserScrolling] = useState(false);

  const toggle = () => setIsNavToggle(!isNavToggle);
  const hide = () => setIsNavToggle(false);
  const show = () => setIsNavToggle(true);

  const { setCurrentSection } = useScrollTo();
  const location = useLocation();

  const isHomePage = () => {
    const path = location.pathname;
    const page = path.split("/")[1];
    // console.log("currentpage; ", page);
    return page === "" || page === "home";
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setUserScrolling(true);
        // console.log("scrolling", userScrolling);
      } else {
        setUserScrolling(false);
      }
    });
  }, []);

  useEffect(() => {
    // console.log("ishome: ", isHomePage());
  }, [location]);

  return (
    <>
      <nav
        className={
          !isHomePage()
            ? `navbar navbar-2 sticky-top py-4`
            : `navbar py-4 ${
                userScrolling && "navbar-sticky shadow-sm py-2 sticky-top"
              }`
        }
      >
        <div className="container">
          <Link
            to="/"
            className="nav__logo d-flex align-items-center text-decoration-none"
            onClick={() => setCurrentSection("link-home")}
          >
            <div
              className={
                isHomePage() ? `${!userScrolling ? "img-bg" : ""}` : ""
              }
            >
              <img src={Logo} alt="Logo" className="logo" />
            </div>
            <span className="fw-bold logo-text">The Outcome Network Inc.</span>
          </Link>
          <ul
            className={`nav__items ${isNavToggle ? "show__nav" : "hide__nav"}`}
          >
            {navigationLinks.map((link, index) => {
              return (
                link.name !== "courses" && (
                  <li key={index}>
                    <NavLink
                      to={link.url}
                      className={({ isActive }) =>
                        (isActive ? "active" : "") + " text-decoration-none"
                      }
                      onClick={() => {
                        toggle();
                        setCurrentSection("link-" + link.name);
                      }}
                      onBlur={hide}
                      onFocus={show}
                    >
                      {link.name}
                    </NavLink>
                  </li>
                )
              );
              // : (
              //   <li key={index} className="dropdown">
              //     <NavLink
              //       to={link.url}
              //       className="dropbtn text-decoration-none"
              //       onClick={() => {
              //         setCurrentSection("link-sub" + link.name);
              //       }}
              //     >
              //       {link.name} <AiOutlineCaretDown size={10} />
              //     </NavLink>

              //     <div className="dropdown-content">
              //       {link.subLinks.map((subLink, index) => {
              //         return (
              //           <NavLink
              //             to={subLink.url}
              //             onClick={() => {
              //               setCurrentSection("link-subs-" + index + link.name);
              //             }}
              //             className=""
              //             key={index}
              //           >
              //             {subLink.name}
              //           </NavLink>
              //         );
              //       })}
              //     </div>
              //   </li>
              // );
            })}
          </ul>
          <button
            id="open__nav-btn"
            onClick={() => setIsNavToggle(!isNavToggle)}
            className={
              isHomePage()
                ? `${
                    userScrolling || isNavToggle
                      ? "text-dark-blue"
                      : "text-white"
                  }`
                : ""
            }
          >
            {isNavToggle ? <MdOutlineClose /> : <FaAlignRight />}
          </button>
        </div>
      </nav>
      {/* <TopButtons /> */}
    </>
  );
};

export default Navbar;
