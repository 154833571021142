import { PopupModal } from "@dannybee/popup-modal";
import React, { useEffect, useState } from "react";
import Quiz from "react-quiz-component";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import { Header, Spinner } from "../../components";
import { useEmail } from "../../contexts/EmailContext";
import "./course-quiz.css";
import { FaDownload } from "react-icons/fa";
import certificate from "../../assets/images/outcome-certificate.png";
import jsPDF from "jspdf";
import "../../assets/fonts/Courgette-Regular-normal";

const CourseQuiz = () => {
  const params = useParams();

  const initialState = {
    name: "",
    email: "",
    subject: "",
    type: "",
    courseName: "",
    awardDate: "",
    score: "",
  };

  const [coursesData, setCoursesData] = useState();
  const [quizData, setQuizData] = useState({});
  const [quiz, setQuiz] = useState();
  const [courseDetail, setCourseDetail] = useState();
  const [loading, setLoading] = useState(true);
  const [quizResult, setQuizResult] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(false);
  const [formState, setState] = useState(initialState);
  const [quizScore, setQuizScore] = useState(0);
  const [studentName, setStudentName] = useState("");
  const [loadingText, setLoadingText] = useState("Processing...");

  const {
    sendCertificateRequestEmail,
    certificateRequestFormRef,
    setFormFields,
    setInitialState,
    statusMessage,
    setStatusMessage,
    formSubmitted,
  } = useEmail();

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const generateCertificate = (e) => {
    e.preventDefault();
    setLoading(true);

    console.log("name: ", studentName);

    let img = new Image();
    let doc = new jsPDF({
      orientation: "landscape",
    });

    img.onload = async function () {
      doc.addImage(this, 0, 0, 297, 210);
      doc.setFontSize(40);
      doc.setFont("Courgette-Regular", "normal");
      // capitalize first letter of each word
      let studentName_ = studentName.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      });
      doc.setTextColor("#093b87");
      doc.text(
        studentName_,
        doc.internal.pageSize.width / 2,
        107,
        null,
        null,
        "center"
      );
      doc.setFontSize(20);
      doc.setFont("courier", "normal");
      doc.text(
        getTodayDate(),
        doc.internal.pageSize.width / 2,
        170,
        null,
        null,
        "center"
      );
      setTimeout(() => {
        doc.save("certificate.pdf");
      }, 3000);

      await delay(5000).then(() => {
        setLoadingText("Tracking Certificate...");
        sendCertificateRequestEmail(e);
        setLoading(false);
      });
    };

    img.crossOrigin = ""; // for demo as we are at different origin than image
    img.src = certificate;
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getTodayDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!
    let yyyy = today.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  };

  const showQuizScore = (obj) => {
    console.log("quiz score: ", obj);

    const getPercentage = () => {
      let percentage = (obj?.correctPoints / obj?.totalPoints) * 100;
      console.log("percentage: ", Math.round(percentage));
      setQuizScore(Math.round(percentage));
      return Math.round(percentage);
    };

    return (
      <>
        <div className="score-section">
          <h1 className="text-center">Quiz Score</h1>

          <div className="pt-3 -center">
            <h3 className="text-center pb-2">
              Total Score:{" "}
              <span className="">
                {obj?.correctPoints} / {obj?.totalPoints}
              </span>
            </h3>

            <div className="my-5">
              <h3
                className={`text-center fw-bold ${
                  getPercentage() > 70 ? "text-success" : "text-danger"
                }`}
              >
                <span
                  className={`border border-3 ${
                    getPercentage() > 70 ? "border-success" : "border-danger"
                  } rounded-circle py-4 px-3`}
                >
                  {getPercentage()}%
                </span>
              </h3>
            </div>

            <p className="mb-0 text-success">
              Number of Correct Answers: {obj?.numberOfCorrectAnswers}
            </p>
            <p className="text-danger mb-2">
              Number of Incorrect Answers: {obj?.numberOfIncorrectAnswers}
            </p>
          </div>

          <div className="d-flex justify-content-center mt-2">
            {getPercentage() > 70 ? (
              <button
                className="btn btn-default resume-btn-dark rounded-pill px-4 py-3"
                onClick={() => setShowPopup(true)}
              >
                Get Certificate
              </button>
            ) : (
              <button
                className="btn btn-default resume-btn-dark rounded-pill px-4 py-3"
                onClick={() => window.location.reload()}
              >
                Retake Quiz
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    setFormFields(formState);
    setInitialState(initialState);
    setStatusMessage("");
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "courses"]{..., coursesList[] { ..., 'slug': slug.current, image->, courseInstructor {..., image->}} }`
      )
      .then((data) => {
        setCoursesData(data[0]);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("course data: ", coursesData);
    let details = coursesData?.coursesList?.find(
      (course) => course.slug === params.id
    );

    console.log("details: ", details);
    setQuizData({
      quizTitle: details?.title + " Quiz",
      nbrOfQuestions: `${details?.quiz?.length}`,
      questions: details?.quiz.map((q) => ({
        question: q.question,
        questionType: "text",
        answerSelectionType: "single",
        answers: q.answers,
        correctAnswer: `${q.correctAnswer}`,
        messageForCorrectAnswer: q.messageForCorrectAnswer,
        messageForIncorrectAnswer: q.messageForIncorrectAnswer,
        point: `${q.point}`,
      })),
    });
    console.log("quiz data: ", quizData);
    setCourseDetail(details);
  }, [coursesData]);

  return (
    <div>
      <Header title={quizData?.quizTitle || "Loading..."} />

      {/* Quiz Section */}
      <section className="container mt-4">
        <div className="d-flex justify-content-center">
          {quizData?.questions && (
            <Quiz
              quiz={quizData}
              shuffle={true}
              continueTillCorrect={true}
              showDefaultResult={false}
              showInstantFeedback={true}
              disableSynopsis={true}
              onComplete={(obj) => setQuizResult(obj)}
              customResultPage={showQuizScore}
            />
          )}
        </div>
      </section>

      {showPopup && (
        <PopupModal handleClose={togglePopup}>
          {/* {status !== 200 ? ( */}
          <div className="p-4">
            <h2 className="pb-2" style={{ textTransform: "uppercase" }}>
              Certificate Request
            </h2>
            {statusMessage && (
              <p
                className={`alertMsg mb-2 text-center ${
                  error ? "text-danger" : "text-success"
                }`}
              >
                {statusMessage}
              </p>
            )}

            {!formSubmitted ? (
              <>
                <p className="mb-0">You have successfully passed the quiz.</p>
                <p className="pb-3">
                  Please fill up the form below to request your certificate.
                </p>

                <form
                  ref={certificateRequestFormRef}
                  onSubmit={(e) => {
                    generateCertificate(e);
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="name">Full Name</label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="name"
                      autoFocus
                      name="name"
                      required
                      placeholder="Enter Your Name"
                      value={studentName}
                      onChange={(e) => setStudentName(e.target.value)}
                    />
                    <input
                      type="hidden"
                      name="type"
                      value="certificate-request"
                    />
                    <input
                      type="hidden"
                      name="subject"
                      value={`A certificate was issued for "${courseDetail?.title}"`}
                    />
                    <input
                      hidden
                      type="hidden"
                      name="courseName"
                      value={courseDetail?.title}
                    />
                    <input
                      hidden
                      type="hidden"
                      name="awardDate"
                      value={new Date().toLocaleDateString({
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    />
                    <input
                      hidden
                      type="hidden"
                      name="score"
                      value={quizScore + "%"}
                    />
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="email">E-mail</label>
                    <input
                      type="email"
                      className="form-control mb-3"
                      id="email"
                      name="email"
                      required
                      placeholder="Enter Your E-mail"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn ml-0 mb-2 btn-default resume-btn-dark d-flex align-items-center rounded-pill px-4 py-2"
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner /> <span className="ms-2">{loadingText}</span>
                      </div>
                    ) : (
                      <>
                        <FaDownload className="me-2" />
                        Download
                      </>
                    )}
                  </button>
                </form>
              </>
            ) : (
              <>
                <p className="pb-3">
                  Thank you for taking the time to complete the training course.
                  Please keep this certificate as your record of completion.
                </p>

                <button
                  className="btn ml-0 mb-2 btn-default resume-btn-dark rounded-pill px-4 py-2"
                  onClick={() => {
                    togglePopup();
                    window.location.replace("/");
                  }}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default CourseQuiz;
