import {
  img2,
  img3,
  img4,
  img5,
  img8,
  img9,
  img10,
  img11,
  img1,
  img6,
  course1,
  course2,
  instructor,
} from "../assets";

import {
  valueIcon1,
  valueIcon2,
  valueIcon3,
  empowerImage,
  member1,
  member2,
  member3,
} from "../assets/images/about";
import {
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  scheduleIcon,
  finderIcon,
  profileIcon,
  inspectionIcon,
  summaryIcon,
} from "../assets/images/services";

export const cards = [
  {
    id: 1,
    title: "per diem coverage",
    details:
      "Flexible, on-demand access to qualified staff from a single shift to several weeks",
    img: img2,
    hash: "LcIX:J_2ivNG~q?bRPRjxubbs:j[",
  },
  {
    id: 2,
    title: "long-Term contingent",
    details:
      "Extended coverage for longer needs, usually 4 to 52 weeks. Remain fully staffed for leaves, adapt to peak demand seasons and access specialized skills for as long as you require.",
    img: img6,
    hash: "LCLNDM000K?^t3M_kW-;00%f%MR5",
  },
  {
    id: 3,
    title: "permanent placement",
    details:
      "Select from among carefully screened candidates, recruited to your specifications. Pay only if you hire a candidate we refer.",
    img: img1,
    hash: "LBG0US00H[c2;YS=_2D*-~8^-rW@",
  },
  {
    id: 4,
    title: "recruitment process outsourcing (RPO)",
    details:
      "Recruitment Process Outsourcing (RPO) is one of our Core Competences; Our RPO services deliver cost-effective recruiting and an efficient solution for attracting and retaining top talent.",
    img: img5,
    hash: "LCL41#_4AH%f_3tkW9_3x^-;xt-=",
  },
  {
    id: 5,
    title: "home care services",
    details:
      "Our in-home care program, managed by our Network recruits, hires, and manages skilled workers for in-home patient care.",
    img: img10,
    hash: "LFK^y+~pcFIVpw0eD%RODh8_aKRj",
  },
  {
    id: 6,
    title: "management service program (MSP)",
    details:
      "We assume 100% responsibility for meeting and managing all your contingent needs.",
    img: img11,
    hash: "LEG8s0_400EKt-i^%1yDHqDit-%h",
  },
  {
    id: 7,
    title: "consulting services",
    details:
      "The Outcome Network consulting practice can help you get there with our targeted engagements and proven solutions to improve productivity, promote efficiency, enhance communication, and increase employee engagement.",
    img: img8,
    hash: "LMIhdB-;D%-;~q?bIAWANb%gxun$",
  },
  {
    id: 8,
    title: "transition planning",
    details:
      "We utilize an advanced Partnership Approach for collaborative Clinical Plan development, emphasizing ongoing refinement and implementation.",
    img: img9,
    hash: "LQHVMDMbE,bw~p9GV?bFtR%N%1xu",
  },
];

export const homeContent = {
  heroSection: [
    {
      title: "About US",
      text: "The Outcome Network is a unique company designed to meet the changing needs in the human services field.",
      image: img6,
      buttons: [
        {
          text: "Read More",
          link: "/about",
        },
      ],
    },
    {
      title: "SERVICES",
      text: "We are dedicated to supporting the person experiencing exceptional needs and their Families through a staffing that displays the highest level of ethics, and exceptional customer service and honors all commitments.",
      image: img9,
      buttons: [
        {
          text: "Request For Service",
          link: "/request-for-service",
        },
        {
          text: "Submit Your Resume",
          link: "/submit-resume",
        },
      ],
    },
    {
      title: "Employers",
      text: "Join Our Team of Professionals. We provide the best training to make sure you are ready for your job.",
      image: img2,
      buttons: [
        {
          text: "Read More",
          link: "/employers",
        },
      ],
    },
  ],

  aboutSection: {
    text: "The Outcome Network is a unique company designed to meet the changing needs in the human services field. The Outcome Network has recognized and met the needs of individuals, service providers, families, and staff, to enhance the services being provided to individuals experiencing disabilities. We are a leader in workforce solution.",
  },

  servicesSection: {
    text: "We are dedicated to supporting the person experiencing exceptional needs and their Families through a staffing that displays the highest level of ethics, and exceptional customer service and honors all commitments.",
  },
};

export const aboutContent = {
  title: "About US",
  paragraph:
    "The Outcome Network is a unique company designed to meet the changing needs in the human services field.",
  missionTitle: "We're on a mission to empower and elevate lives.",
  missionParagraph:
    "The Outcome Network has recognized and met the needs of individuals, service providers, families, and staff, to enhance the services being provided to individuals experiencing disabilities.",
  missionParagraph2: "We are a leader in workforce solutions including:",
  solutions: [
    "Temporary Staffing Services",
    "Behavior Support",
    "Transportation of People to their Community of Belonging",
    "Volunteer Supports",
    "Translation Services",
    "Online Training",
    "Compliance and Program Consulting",
  ],
  uniqueTitle: "Our Uniqueness",
  uniqueParagraph:
    "The services we provide are as unique and diverse as the agencies and people we work with and are tailored to meet each agency’s specific requirements. Many customers often use our supports heavily through times of change, expansion, or uncertainty and then adjust when needs stabilize.",
  uniqueParagraph2:
    "Other customers use us permanently for certain tasks or supports as part of their organizational design.",
  valuesTitle: "Our Values",
  valuesParagraph:
    "Through these values, The Outcome Network will help you gain the skills, understanding, and confidence to succeed in this field",
  values: [
    {
      title: "Training",
      description:
        "We help you gain training necessary for you to succeed in your field of interest.",
      img: valueIcon1,
    },
    {
      title: "Consulting",
      description:
        "We provide expert advise to raise your awareness and understanding in your field of interest.",
      img: valueIcon2,
    },
    {
      title: "Customized Supports",
      description:
        "We provide customized support to assist you all the way till you achieve success.",
      img: valueIcon3,
    },
  ],
  empowerSection: {
    title: "Empowering Disabled Individuals Through Skilled Support",
    paragraph:
      "We Support and Empower people experiencing disabilities to live a life of their choosing.",
    videoImg: empowerImage,
  },
  teamSection: {
    title: "Our Team",
    paragraph:
      "Our consultants, trainers, and staffing experts are excited and ready to assist you in your areas of need!",
    members: [
      {
        name: "Princy Flora",
        title: "Co-Founder & CEO",
        img: member1,
        socialLinks: [
          {
            name: "facebook",
            url: "https://www.facebook.com/princy.flora.1",
          },
          {
            name: "twitter",
            url: "https://twitter.com/princyflora",
          },
          {
            name: "linkedin",
            url: "https://www.linkedin.com/in/princy-flora-9b1b1b1b/",
          },
        ],
      },
      {
        name: "Stefhen Richards",
        title: "CEO & Art Director",
        img: member2,
        socialLinks: [
          {
            name: "facebook",
            url: "https://www.facebook.com/stefhen.richards",
          },
          {
            name: "twitter",
            url: "https://twitter.com/stefhenrichards",
          },
          {
            name: "linkedin",
            url: "https://www.linkedin.com/in/stefhen-richards-9b1b1b1b/",
          },
        ],
      },
      {
        name: "Katherine",
        title: "Head of Finance",
        img: member3,
        socialLinks: [
          {
            name: "facebook",
            url: "https://www.facebook.com/katherine.richards.376",
          },
          {
            name: "twitter",
            url: "https://twitter.com/katherinerichards",
          },
          {
            name: "linkedin",
            url: "https://www.linkedin.com/in/katherine-richards-9b1b1b1b/",
          },
        ],
      },
    ],
  },
};

export const servicesContent = {
  title: "Our Services",
  paragraph:
    "We are dedicated to support the person experiencing exceptional needs and their families through a Staffing that displays the highest level of ethics, exceptional customer service and honor all commitments.",
  section1: {
    title: "Staffing",
    paragraph:
      "We are dedicated to support the person experiencing exceptional needs and their families through a Staffing that displays the highest level of ethics, exceptional customer service and honor all commitments.",
    img: service1,
  },
  section2: {
    title: "Transition Planning",
    paragraph:
      "Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.",
    img: service2,
    list: [
      {
        title: "Smart Schedule",
        description: "Building an enterprise doesn't need your thousands.",
        icon: scheduleIcon,
      },
      {
        title: "Hashtag Finder",
        description: "Building an enterprise doesn't need your thousands.",
        icon: finderIcon,
      },
    ],
  },
  section3: {
    title: "Clinical Risk Process",
    paragraph:
      "Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.",
    paragraph2:
      "Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease .",
    img: service3,
    list: [
      {
        title: "Profile Performance",
        description: "Building an enterprisedoesn't need  your thousands.",
        icon: profileIcon,
      },
      {
        title: "Post Inspector",
        description: "Building an enterprisedoesn't need  your thousands.",
        icon: inspectionIcon,
      },
      {
        title: "Summary Reports",
        description: "Building an enterprisedoesn't need  your thousands.",
        icon: summaryIcon,
      },
    ],
  },
  section4: {
    title: "Treatment Plans",
    paragraph:
      "Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.",
    img: service4,
    list: [
      {
        title: "Smart Schedule",
        description: "Building an enterprise doesn't need your thousands.",
        icon: scheduleIcon,
      },
      {
        title: "Hashtag Finder",
        description: "Building an enterprise doesn't need your thousands.",
        icon: finderIcon,
      },
    ],
  },
  section5: {
    title: "Training and Development Programs",
    paragraph:
      "We are dedicated to support the person experiencing exceptional needs and their families through a Staffing that displays the highest level of ethics, exceptional customer service and honor all commitments.",
    img: service5,
  },
  section6: {
    title: "Translation Services",
    paragraph:
      "Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.",
    img: service6,
    list: [
      {
        title: "English to French",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        icon: scheduleIcon,
      },
      {
        title: "French to English",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        icon: finderIcon,
      },
    ],
  },
};

export const employersContent = {
  title: "Our Service Providers",
  paragraph:
    "The Outcome Network will provide you with quality staff and lower costs for everyone. We understand that staffing is about more than just filling a position. Our focus is to actively recruit and carefully screen direct support professionals to provide the trusted network of the best healthcare talent when your need arises. ",
  positionTypes: [
    {
      label: "Temporary",
      value: "temporary",
    },
    {
      label: "Permanent",
      value: "permanent",
    },
    {
      label: "Full-Time",
      value: "full-time",
    },
    {
      label: "Part-Time",
      value: "part-time",
    },
  ],
};

export const careersContent = {
  title: "Careers",
  paragraph:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  caption: "Become a part of our big family to inspire and get inspired by ",
  caption2: "professional experts.",
  jobList: [
    {
      department: "Marketing",
      positions: [
        {
          title: "Copywriter (Intermediate)",
          location: "San Francisco, CA",
          positionType: "Part Time",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        },
        {
          title: "Copywriter (Intermediate)",
          location: "New York",
          positionType: "Part Time",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        },
      ],
    },
    {
      department: "Development",
      positions: [
        {
          title: "Copywriter (Intermediate)",
          location: "San Francisco, CA",
          positionType: "Part Time",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        },
        {
          title: "Dev Ops Engineer",
          location: "Remote",
          positionType: "Part Time",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        },
        {
          title: "Frontend Javascript Team Lead",
          location: "Remote",
          positionType: "Part Time",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        },
      ],
    },
    {
      department: "Product",
      positions: [
        {
          title: "Product Manager (Payments & Monetization)",
          location: "Toronto",
          positionType: "Part Time",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        },
        {
          title: "UX Designer (Intermediate - Senior)",
          location: "New York",
          positionType: "Part Time",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        },
      ],
    },
  ],
};

export const contactDetails = {
  title: "Contact Us",
  email: "info@theoutcomenetwork.ca",
  phone: "(+1) 416-995-9105",
  location: "Barrie Ontario",

  caption: "How Can We Help You?",
  paragraph:
    "Please fill out the form drop your inquiry and a member of our team will reach out shortly.",
};

export const coursesContent = {
  title: "Courses",
  paragraph:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  list: [
    {
      id: "1",
      title: "Course Lorem Ipsum Title",
      author: "Author Name",
      img: course1,
      description:
        "In this course you will learn how to create a beautiful website with Felix. This course is designed for the absolute beginner, meaning no experience with Felix is required. You will start by learning how to plan your website by creating a site map and gathering content.",
      lessons: [
        {
          title: "Lesson 1",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 2",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 3",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 4",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 5",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 6",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
      ],
      aboutCourse: {
        title: "About this course",
        paragraph:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        list: [
          "Listen to what they say about you",
          "Randomised words which don't look even slightly believable.",
          "Lorem Ipsum generators on the Internet tend to repeat predefined chunks",
          "Automate multiple scenarios and eliminate tedious tasks. ",
        ],
        tags: ["Analyze", "Design", "Develop", "Implement", "Evaluate"],
      },
      instructor: {
        name: "Caroline Parker",
        img: instructor,
        about:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s.",
        position: "Marketing Leader",
        socialLinks: [
          {
            name: "facebook",
            url: "https://www.facebook.com/",
          },
          {
            name: "twitter",
            url: "https://twitter.com/",
          },
          {
            name: "linkedin",
            url: "https://www.linkedin.com/",
          },
        ],
      },
    },
    {
      id: "2",
      title: "Course Lorem Ipsum Title",
      author: "Author Name",
      img: course2,
      description:
        "In this course you will learn how to create a beautiful website with Felix. This course is designed for the absolute beginner, meaning no experience with Felix is required. You will start by learning how to plan your website by creating a site map and gathering content.",
      lessons: [
        {
          title: "Lesson 1",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 2",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 3",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 4",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 5",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 6",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
      ],
      aboutCourse: {
        title: "About this course",
        paragraph:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        list: [
          "Listen to what they say about you",
          "Randomised words which don't look even slightly believable.",
          "Lorem Ipsum generators on the Internet tend to repeat predefined chunks",
          "Automate multiple scenarios and eliminate tedious tasks. ",
        ],
        tags: ["Analyze", "Design", "Develop", "Implement", "Evaluate"],
      },
      instructor: {
        name: "Caroline Parker",
        img: instructor,
        about:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s.",
        position: "Marketing Leader",
        socialLinks: [
          {
            name: "facebook",
            url: "https://www.facebook.com/",
          },
          {
            name: "twitter",
            url: "https://twitter.com/",
          },
          {
            name: "linkedin",
            url: "https://www.linkedin.com/",
          },
        ],
      },
      socialLinks: [
        {
          name: "facebook",
          url: "https://www.facebook.com/",
        },
        {
          name: "twitter",
          url: "https://twitter.com/",
        },
        {
          name: "linkedin",
          url: "https://www.linkedin.com/",
        },
      ],
    },
    {
      id: "3",
      title: "Course Lorem Ipsum Title",
      author: "Author Name",
      img: course2,
      description:
        "In this course you will learn how to create a beautiful website with Felix. This course is designed for the absolute beginner, meaning no experience with Felix is required. You will start by learning how to plan your website by creating a site map and gathering content.",
      lessons: [
        {
          title: "Lesson 1",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 2",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 3",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 4",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 5",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 6",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
      ],
      aboutCourse: {
        title: "About this course",
        paragraph:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        list: [
          "Listen to what they say about you",
          "Randomised words which don't look even slightly believable.",
          "Lorem Ipsum generators on the Internet tend to repeat predefined chunks",
          "Automate multiple scenarios and eliminate tedious tasks. ",
        ],
        tags: ["Analyze", "Design", "Develop", "Implement", "Evaluate"],
      },
      instructor: {
        name: "Caroline Parker",
        img: instructor,
        about:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s.",
        position: "Marketing Leader",
        socialLinks: [
          {
            name: "facebook",
            url: "https://www.facebook.com/",
          },
          {
            name: "twitter",
            url: "https://twitter.com/",
          },
          {
            name: "linkedin",
            url: "https://www.linkedin.com/",
          },
        ],
      },
      socialLinks: [
        {
          name: "facebook",
          url: "https://www.facebook.com/",
        },
        {
          name: "twitter",
          url: "https://twitter.com/",
        },
        {
          name: "linkedin",
          url: "https://www.linkedin.com/",
        },
      ],
    },
    {
      id: "4",
      title: "Course Lorem Ipsum Title",
      author: "Author Name",
      img: course1,
      description:
        "In this course you will learn how to create a beautiful website with Felix. This course is designed for the absolute beginner, meaning no experience with Felix is required. You will start by learning how to plan your website by creating a site map and gathering content.",
      lessons: [
        {
          title: "Lesson 1",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 2",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 3",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 4",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 5",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 6",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
      ],
      aboutCourse: {
        title: "About this course",
        paragraph:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        list: [
          "Listen to what they say about you",
          "Randomised words which don't look even slightly believable.",
          "Lorem Ipsum generators on the Internet tend to repeat predefined chunks",
          "Automate multiple scenarios and eliminate tedious tasks. ",
        ],
        tags: ["Analyze", "Design", "Develop", "Implement", "Evaluate"],
      },
      instructor: {
        name: "Caroline Parker",
        img: instructor,
        about:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s.",
        position: "Marketing Leader",
        socialLinks: [
          {
            name: "facebook",
            url: "https://www.facebook.com/",
          },
          {
            name: "twitter",
            url: "https://twitter.com/",
          },
          {
            name: "linkedin",
            url: "https://www.linkedin.com/",
          },
        ],
      },
      socialLinks: [
        {
          name: "facebook",
          url: "https://www.facebook.com/",
        },
        {
          name: "twitter",
          url: "https://twitter.com/",
        },
        {
          name: "linkedin",
          url: "https://www.linkedin.com/",
        },
      ],
    },
    {
      id: "5",
      title: "Course Lorem Ipsum Title",
      author: "Author Name",
      img: course1,
      description:
        "In this course you will learn how to create a beautiful website with Felix. This course is designed for the absolute beginner, meaning no experience with Felix is required. You will start by learning how to plan your website by creating a site map and gathering content.",
      lessons: [
        {
          title: "Lesson 1",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 2",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 3",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 4",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 5",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 6",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
      ],
      aboutCourse: {
        title: "About this course",
        paragraph:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        list: [
          "Listen to what they say about you",
          "Randomised words which don't look even slightly believable.",
          "Lorem Ipsum generators on the Internet tend to repeat predefined chunks",
          "Automate multiple scenarios and eliminate tedious tasks. ",
        ],
        tags: ["Analyze", "Design", "Develop", "Implement", "Evaluate"],
      },
      instructor: {
        name: "Caroline Parker",
        img: instructor,
        about:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s.",
        position: "Marketing Leader",
        socialLinks: [
          {
            name: "facebook",
            url: "https://www.facebook.com/",
          },
          {
            name: "twitter",
            url: "https://twitter.com/",
          },
          {
            name: "linkedin",
            url: "https://www.linkedin.com/",
          },
        ],
      },
      socialLinks: [
        {
          name: "facebook",
          url: "https://www.facebook.com/",
        },
        {
          name: "twitter",
          url: "https://twitter.com/",
        },
        {
          name: "linkedin",
          url: "https://www.linkedin.com/",
        },
      ],
    },
    {
      id: "6",
      title: "Course Lorem Ipsum Title",
      author: "Author Name",
      img: course2,
      description:
        "In this course you will learn how to create a beautiful website with Felix. This course is designed for the absolute beginner, meaning no experience with Felix is required. You will start by learning how to plan your website by creating a site map and gathering content.",
      lessons: [
        {
          title: "Lesson 1",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 2",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 3",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 4",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
        {
          title: "Lesson 5",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "rokGy0huYEA",
        },
        {
          title: "Lesson 6",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.",
          embedId: "aDx-vcSFM7k",
        },
      ],
      aboutCourse: {
        title: "About this course",
        paragraph:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s, when an unknown printegalley of type and scrambled it to make a type specimen book.",
        list: [
          "Listen to what they say about you",
          "Randomised words which don't look even slightly believable.",
          "Lorem Ipsum generators on the Internet tend to repeat predefined chunks",
          "Automate multiple scenarios and eliminate tedious tasks. ",
        ],
        tags: ["Analyze", "Design", "Develop", "Implement", "Evaluate"],
      },
      instructor: {
        name: "Caroline Parker",
        img: instructor,
        about:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the industry's standard dummy text ever since the 1500s.",
        position: "Marketing Leader",
        socialLinks: [
          {
            name: "facebook",
            url: "https://www.facebook.com/",
          },
          {
            name: "twitter",
            url: "https://twitter.com/",
          },
          {
            name: "linkedin",
            url: "https://www.linkedin.com/",
          },
        ],
      },
      socialLinks: [
        {
          name: "facebook",
          url: "https://www.facebook.com/",
        },
        {
          name: "twitter",
          url: "https://twitter.com/",
        },
        {
          name: "linkedin",
          url: "https://www.linkedin.com/",
        },
      ],
    },
  ],
};

export const trainingContent = {
  title: "The Outcome Network Training Registration",
  courseList: [
    {
      label: "Introduction to Applied Behaviour Analysis (ABA)",
      value: "Introduction to Applied Behaviour Analysis (ABA)",
    },
    {
      label: "Medication and Pharmacology",
      value: "Medication and Pharmacology",
    },
    {
      label: "Safety Management",
      value: "Safety Management",
    },
    {
      label: "First Aid and CPR",
      value: "First Aid and CPR",
    },
    {
      label: "Understanding and Working with Autism and Asperger's",
      value: "Understanding and Working with Autism and Asperger's",
    },
    {
      label:
        "Understanding and Working with Fetal Alcohol Spectrum Disorder (FASD)",
      value:
        "Understanding and Working with Fetal Alcohol Spectrum Disorder (FASD)",
    },
    {
      label: "Understanding Behaviour Support Plans",
      value: "Understanding Behaviour Support Plans",
    },
    {
      label:
        "Overview of the Principles of Positive Behaviour Support for Direct Support Professionals",
      value:
        "Overview of the Principles of Positive Behaviour Support for Direct Support Professionals",
    },
    {
      label: "Supervision and the Principles of Positive Behaviour Support",
      value: "Supervision and the Principles of Positive Behaviour Support",
    },
    {
      label: "Autism Spectrum Disorders: Separating the Data from the Myths",
      value: "Autism Spectrum Disorders: Separating the Data from the Myths",
    },
    {
      label: "CBT for People with Developmental Disabilities",
      value: "CBT for People with Developmental Disabilities",
    },
    {
      label:
        "Nutrition & Exercise for Individuals with Developmental Disabilities",
      value:
        "Nutrition & Exercise for Individuals with Developmental Disabilities",
    },
    {
      label: "Practical Implications of Genetic Diagnoses for People with DD",
      value: "Practical Implications of Genetic Diagnoses for People with DD",
    },
    {
      label: "Diagnosing Substance Abuse in Older Adults",
      value: "Diagnosing Substance Abuse in Older Adults",
    },
  ],
};

export const courseEnrollmentContent = {
  title: "Course Title Lorem Ipsum",
  paragraph:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  captionTitle: "Enroll today",
  captionParagraph:
    "Your personal data will be used to process your enrollment, support your experience on this website, and for other purposes described in our ",
};

export const requestForServiceContent = {
  title: "Request an employee",
  paragraph:
    "Are you looking for an outstanding staff? Please complete the form below or contact us directly:",
};
