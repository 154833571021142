import React from "react";
import "./cards.css";
import { cards } from "../../constants/data";
import { Blurhash } from "react-blurhash";
import Img from "../Img";

const Cards = () => {
  return (
    <>
      <div className="row card-group">
        {cards.map((item, index) => (
          <div key={"card#" + index} className="col-lg-3 col-md-4 col-12 pb-4">
            <div className="card h-100" key={item.hash + "#" + item.id}>
              <div className="top">
                <div className="card-img">
                  <Img
                    hash={item.hash}
                    src={item.img}
                    height={352}
                    width={"100%"}
                  />
                </div>
                <div className="cardOverlay">
                  <div className="details">
                    <p>{item.details}</p>
                  </div>
                </div>
              </div>
              <div className="cardText">
                <h4>{item.title}</h4>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Cards;
