import React from "react";
import "./translation.css";

const Translation = () => {
  return (
    <>
      <div>Translation</div>
    </>
  );
};

export default Translation;
