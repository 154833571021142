// scroll to top and scroll to specific ref section context

import { createContext, useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaChevronUp } from "react-icons/fa";

export const ScrollToContext = createContext()

export const ScrollToProvider = ({ children }) => {
    const [currentSection, setCurrentSection] = useState("empty")
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    const scrollToSection = (elementRef) => {
        // console.log("currentSection: ", currentSection)
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setShowScrollTopButton(true);
            } else {
                setShowScrollTopButton(false);
            }
        });
    }, []);

    useEffect(() => {
        // console.log("currentSection 2: ", currentSection)

        if (currentSection.includes("link")) {
            scrollToTop()
        }
    }, [currentSection])

    return (
        <ScrollToContext.Provider value={{ scrollToSection, scrollToTop, currentSection, setCurrentSection }}>
            {children}
            {showScrollTopButton && <motion.button initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }} className="btn btn-default scrollTop" onClick={scrollToTop}> <FaChevronUp size={15} />
            </motion.button>}
        </ScrollToContext.Provider>
    )
}

export const useScrollTo = () => useContext(ScrollToContext)