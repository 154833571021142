import { defineField, defineType } from "sanity";
import { quizQuestion } from "./defaultData";
import ArrayMaxItems from "../components/ArrayMaxItems";

export default defineType({
    name: "question",
    title: "Question",
    type: "object",
    fields: [
        defineField({
            name: "question",
            title: "Question",
            type: "text",
            description: "What is this question about?",
            rows: 3,
        }),
        defineField({
            name: "questionType",
            title: "Question Type",
            type: "string",
            defaultValue: "text",
            hidden: true,
        }),
        defineField({
            name: "answerSelectionType",
            title: "Answer Selection Type",
            type: "string",
            defaultValue: "single",
            hidden: true,
        }),
        defineField({
            name: "answers",
            title: "Answers",
            description: "The answers for this question",
            type: "array",
            readOnly: true,
            of: [{ type: "string" }],
            initialValue: [...quizQuestion.answers],
            components: { input: ArrayMaxItems },
            validation: Rule => Rule.max(2).warning('You can only have 2 answers')
        }),
        defineField({
            name: "correctAnswer",
            title: "Correct Answer",
            description: "Select the correct answer",
            type: "number",
            options: {
                layout: "radio",
                list: [...quizQuestion.answerOptions],
            },
        }),
        defineField({
            name: "messageForCorrectAnswer",
            title: "Message For Correct Answer",
            type: "string",
            description: "Add a message for the correct answer",
            initialValue: "Correct answer. Good job.",
        }),
        defineField({
            name: "messageForIncorrectAnswer",
            title: "Message For Incorrect Answer",
            type: "string",
            description: "Add a message for the incorrect answer",
            initialValue: "Incorrect answer. Please try again.",
        }),
        defineField({
            name: "point",
            title: "Point",
            type: "number",
            description: "How many points is this question worth?",
            initialValue: 10,
        }),
    ],

    preview: {
        select: {
            title: 'question',
        },
    }
})