import { createRef, useRef } from "react";

export const useRefLinks = () => {
    const mission = useRef()
    const uniqueness = useRef()
    const culture = useRef()
    const staffing = useRef()
    const transitionPlanning = useRef()
    const clinicalRiskProcess = useRef()
    const treatmentPlans = useRef()
    const trainingAndDevelopmentPrograms = useRef()
    const translationServices = useRef()


    const ourStory = [
        {
            id: "1",
            name: "mission",
            refname: "mission",
            url: "/about"
        },

        {
            id: "2",
            name: "how are we unique",
            refname: "uniqueness",
            url: "/about"
        },
        {
            id: "3",
            name: "our culture",
            refname: "culture",
            url: "/about"
        },
    ];

    const ourServiceLinks = [
        {
            id: "1",
            name: "staffing",
            url: '/services',
            refname: "staffing"
        },

        {
            id: "2",
            name: "transition planning",
            url: '/services',
            refname: "transition-planning"
        },
        {
            id: "3",
            name: "treatment plans",
            url: '/services',
            refname: "treatment-plans"
        },
        {
            id: "4",
            name: "training and development programs",
            url: '/services',
            refname: "training-and-development-programs"
        },
        {
            id: "5",
            name: "translation services",
            url: '/services',
            refname: "tranlation-services"
        },
    ]

    return [ourStory, ourServiceLinks, mission, uniqueness, culture, staffing, transitionPlanning, clinicalRiskProcess, treatmentPlans, trainingAndDevelopmentPrograms, translationServices]

}