import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { useRoutes } from "react-router-dom";
import "video-react/dist/video-react.css";
import sanityClient from "./client";
import { EmailProvider } from "./contexts/EmailContext";
import { routes } from "./routes";
import { AboutProvider } from "./contexts/AboutContext";
import { useAboutContext } from "./contexts/AboutContext";

function App() {
  const [siteSettings, setSiteSettings] = useState()

  const routing = useRoutes(routes)


  useEffect(() => {
    sanityClient.fetch(`*[_type == 'siteSettings']`).then((data) => {
      setSiteSettings(data[0])
    }).catch(console.error)
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="title" content={siteSettings?.title} />
        <meta
          name="description"
          content={siteSettings?.description}
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kammina.com" />
        <meta property="og:title" content={siteSettings?.title} />
        <meta
          property="og:description"
          content={siteSettings?.description}
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://kammina.com" />
        <meta property="twitter:title" content={siteSettings?.title} />
        <meta
          property="twitter:description"
          content={siteSettings?.description}
        />

        <title>{siteSettings?.title}</title>
      </Helmet>
      <AboutProvider>
        <EmailProvider>
          <SkeletonTheme baseColor="#d7d7d7" highlightColor="#bcbcbc">
            {routing}
          </SkeletonTheme>
        </EmailProvider>
      </AboutProvider>
    </HelmetProvider>
  );
}

export default App;
