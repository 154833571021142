import blockContent from "./blockContent";
import category from "./category";
import post from "./post";
import author from "./author";
import home from "./home";
import about from "./about";
import gallery from "./gallery";
import services from "./services";
import employers from "./employers";
import courses from "./courses";
import course from "./course";
import lesson from "./lesson";
import registerCourse from "./register-course";
import requestForService from "./request-for-service";
import submitResume from "./submit-resume";
import contact from "./contact";
import slider from "./slider";
import button from "./button";
import siteSettings from "./siteSettings";
import question from "./question";

export const schemaTypes = [
    home,
    about,
    gallery,
    services,
    employers,
    course,
    lesson,
    courses,
    registerCourse,
    requestForService,
    submitResume,
    contact,
    button,
    question,
    slider,
    siteSettings,
    blockContent
];
