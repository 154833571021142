import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { banner2 } from "../../assets";
import { Header } from "../../components";
import { employersContent } from "../../constants/data";
import { useWindowDimensions } from "../../hooks/windowDimensions";
import "./employers.css";
import EmployeeForm from "../../components/EmployeeForm";
import sanityClient from "../../client.js";

const Employers = () => {
  const [employersData, setEmployersData] = useState({});
  const [loading, setLoading] = useState(true);

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const descriptionExists = () =>
    employersData?.hasOwnProperty("headerDescription");

  useEffect(() => {
    sanityClient.fetch(`*[_type == "employers"]`).then((data) => {
      setEmployersData(data[0]);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log("employers data: ", employersData);
  }, [employersData]);

  return (
    <div id="employers">
      <Header
        title={employersData?.headerTitle}
        bannerImg={banner2}
        height={windowWidth <= 991 ? "400px" : "600px"}
      >
        {descriptionExists() ? (
          <p className="employersText">
            {employersData?.headerDescription || "Loading..."}
          </p>
        ) : (
          loading && <p>Loading...</p>
        )}
      </Header>

      {/* Form */}
      <EmployeeForm />
    </div>
  );
};

export default Employers;
