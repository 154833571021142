import React, { useEffect, useState } from "react";
import { banner2 } from "../../assets";
import { Header } from "../../components";
import { useWindowDimensions } from "../../hooks/windowDimensions";
import "./resume.css";
import { motion } from "framer-motion";
import sanityClient from "../../client";
import { useEmail } from "../../contexts/EmailContext";

const SubmitResume = () => {
  const initialState = {
    name: "",
    email: "",
    subject: "",
    phone: "",
    type: "",
    file: {},
    message: "",
  };

  const [formState, setState] = useState(initialState);
  const [error, setError] = useState(false);
  const [submitResumeData, setSubmitResumeData] = useState();
  const [loading, setLoading] = useState(true);

  const {
    sendResumeEmail,
    resumeFormRef,
    setFormFields,
    setInitialState,
    statusMessage,
    setStatusMessage,
  } = useEmail();

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const descriptionExists = () =>
    submitResumeData?.hasOwnProperty("headerDescription");

  const handleFileChange = (event) => {
    let file = event.target.files[0];

    if (
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setState({ ...formState, file });
      setError(false);
      setStatusMessage("");
    } else {
      event.target.value = null;
      setState({ ...formState, file: null });
      setError(true);
      setStatusMessage(
        "Invalid file type. Only PDF and Word documents are allowed."
      );
    }
  };

  useEffect(() => {
    // console.log("our Story: ", ourStory);
    setFormFields(formState);
    setInitialState(initialState);
    setStatusMessage("");

    sanityClient
      .fetch(`*[_type == "submitResume"]`)
      .then((data) => {
        setSubmitResumeData(data[0]);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  return (
    <div id="resume">
      <Header
        title={submitResumeData?.headerTitle}
        bannerImg={banner2}
        height={windowWidth <= 991 ? "400px" : "500px"}
      >
        {descriptionExists() ? (
          <p className="mt-3">
            {submitResumeData?.headerDescription || "Loading..."}
          </p>
        ) : (
          loading && <p>Loading...</p>
        )}
      </Header>

      {/* Form */}
      <motion.section
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "tween", duration: 0.7 }}
        className="container form-section"
      >
        <div className="row mx-auto">
          <div className="col-12 form-container">
            <form
              ref={resumeFormRef}
              onSubmit={sendResumeEmail}
              className="rounded-4 shadow-lg d-flex flex-column justify-content-center px-4 py-5 px-md-5"
            >
              <div className="d-flex flex-column justify-content-center mb-5">
                <h4 className="text-center">Your Contact Information</h4>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  {/* full name */}
                  <div className="mb-4 form-group">
                    <label htmlFor="Name" className="fw-bold">
                      Full Name *
                    </label>
                    <input
                      id="Name"
                      type="text"
                      name="name"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                    <input
                      type="hidden"
                      name="subject"
                      value="Resume Form Submission"
                    />
                    <input type="hidden" name="type" value="resume" />
                  </div>

                  {/* phone number */}
                  <div className="mb-4 form-group">
                    <label htmlFor="phoneNumber" className="fw-bold">
                      Phone Number *
                    </label>
                    <input
                      id="phoneNumber"
                      type="text"
                      name="phone"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  {/* email */}
                  <div className="mb-4 form-group">
                    <label htmlFor="email" className="fw-bold">
                      Your Email *
                    </label>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>

                  {/* resume upload */}
                  <div className="mb-4 form-group">
                    <label htmlFor="resumeFile" className="fw-bold">
                      Upload Your resume *
                    </label>
                    <input
                      type="file"
                      id="resumeFile"
                      name="file"
                      required
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className="col-12">
                  {/* message */}
                  <div className="col-12">
                    <div className="mb-4 form-group">
                      <label htmlFor="resumeMessage" className="fw-bold">
                        Message (optional)
                      </label>
                      <textarea
                        name="message"
                        id="resumeMessage"
                        cols="30"
                        rows="8"
                        className="form-control rounded-4 mt-2 py-3 px-4"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-4">
                <button
                  type="submit"
                  className="btn py-3 px-5 btn-default rounded-5 form-btn-dark fw-bold"
                >
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default SubmitResume;
