import React, { useEffect, useState } from "react";
import { banner2 } from "../../assets";
import { Header } from "../../components";
import EmployeeForm from "../../components/EmployeeForm";
import { requestForServiceContent } from "../../constants/data";
import { useWindowDimensions } from "../../hooks/windowDimensions";
import "./request.css";
import sanityClient from "../../client";

const RequestForService = () => {
  const [requestForServiceData, setRequestForServiceData] = useState();
  const [loading, setLoading] = useState(true);

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const descriptionExists = () =>
    requestForServiceData?.hasOwnProperty("headerDescription");

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "requestForService"]`)
      .then((data) => setRequestForServiceData(data[0]))
      .catch(console.error);
  }, []);

  return (
    <div id="request">
      <Header
        title={requestForServiceData?.headerTitle}
        bannerImg={banner2}
        height={windowWidth <= 991 ? "400px" : "500px"}
      >
        {descriptionExists() ? (
          <p className="mt-3">
            {requestForServiceData?.headerDescription || "Loading..."}
          </p>
        ) : (
          loading && <p>Loading...</p>
        )}
      </Header>

      {/* form */}
      <EmployeeForm />
    </div>
  );
};

export default RequestForService;
