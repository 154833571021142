import React from "react";
import "./transition.css";

const TransitionPlanning = () => {
  return (
    <>
      <div>TransitionPlanning</div>
    </>
  );
};

export default TransitionPlanning;
