import React from "react";
import "./clinical_risk.css";

const ClinicalRisk = () => {
  return (
    <>
      <div>ClinicalRisk</div>
    </>
  );
};

export default ClinicalRisk;
