import React from "react";
import "./career.css";
import { GetStartedBanner, Header } from "../../components";
import { careersContent } from "../../constants/data";

const Career = () => {
  return (
    <div>
      <Header title={careersContent.title}>
        <p className="lead">{careersContent.paragraph}</p>
      </Header>

      {/* caption */}
      <section className="container py-5">
        <div className="d-flex flex-column justify-content-center text-center caption-container mx-auto">
          <h4 className="mb-0">{careersContent.caption}</h4>
          <h4 className="" style={{ color: "var(--sea-blue)" }}>
            {careersContent.caption2}
          </h4>
        </div>
      </section>

      {/* job listings */}
      <section className="container">
        {careersContent.jobList.map((item, index) => (
          <div key={"jobList#" + index} className="row mb-5">
            <div className="col-12 pb-4">
              <h4>{item.department}</h4>
            </div>

            {item.positions.map((position, index) => (
              <div className="row mb-3 mb-md-auto">
                <div className="col-12 col-md-12 col-lg-1"></div>
                <div className="col-12 col-md-12 col-lg-9">
                  <h5 className="fw-bold mb-1">{position.title}</h5>
                  <div className="">
                    <small className="">{position.location}</small> |&nbsp;
                    <small style={{ color: "var(--sea-blue)" }}>
                      {position.positionType}
                    </small>
                  </div>

                  <p className="mb-4">{position.description}</p>
                </div>
                <div className="col-12 col-md-12 col-lg-2">
                  <button className="btn py-3 px-4 btn-default rounded-5 form-btn-light fw-bold">
                    Apply Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}
      </section>

      <GetStartedBanner />
    </div>
  );
};

export default Career;
