import React from "react";
import Skeleton from "react-loading-skeleton";

const TwoLineSkeleton = () => {
  return (
    <p className="text-center">
      <Skeleton width={"100%"} />
      <Skeleton width={"80%"} />
    </p>
  );
};

export default TwoLineSkeleton;
