import React, { useEffect } from "react";
import { Blurhash } from "react-blurhash";

const Img = ({
  hash,
  src,
  height = "100%",
  width = "100%",
  alt = "",
  classNames = ["img-fluid"],
}) => {
  const [imgLoaded, setImgLoaded] = React.useState(false);
  const [imgError, setImgError] = React.useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgLoaded(true);
    };
    img.onerror = () => {
      setImgError(true);
    };
  }, [src]);

  return (
    <>
      <div style={{ display: imgLoaded ? "none" : "inline" }}>
        <Blurhash
          hash={hash}
          width={width}
          height={height}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>

      <img
        src={src}
        style={{ display: !imgLoaded ? "none" : "inline" }}
        className={classNames}
        loading="lazy"
        alt={alt}
      />
    </>
  );
};

export default Img;
