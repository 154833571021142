import React from "react";
import "./header.css";
import { banner } from "../../assets";

const index = ({
  title,
  children,
  height = "400px",
  bannerImg = banner,
  variant = "default",
}) => {
  return (
    <header
      className="masthead"
      style={{ height: height, backgroundImage: `url(${bannerImg})` }}
    >
      <div className="container h-100">
        {variant === "default" ? (
          <div className="row h-100 align-items-center justify-content-center">
            <div className="col-12 text-center head-content">
              <h1 className="fw-light">{title}</h1>
              {children}
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </header>
  );
};

export default index;
