export const leftColAnimation = {
    hidden: {
        x: -200,
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1,
    }
}

export const rightColAnimation = {
    hidden: {
        x: 200,
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1,
    }
}

export const verticalAnimation = {
    hidden: {
        y: 300,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
    }
}

export const fadeInAnimation = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 1
        }
    }
}