import React from "react";
import "./training.css";

const TrainingAndDevelopment = () => {
  return (
    <>
      <div>TrainingAndDevelopment</div>
    </>
  );
};

export default TrainingAndDevelopment;
