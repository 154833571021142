import { defineField, defineType } from "sanity";
import { servicesContent } from "./defaultData";

const servicesList = [
  {
    title: servicesContent.section1.title,

  },
  {
    title: servicesContent.section2.title,

  },
  {
    title: servicesContent.section3.title,

  },
  {
    title: servicesContent.section4.title,
  },
  {
    title: servicesContent.section5.title,
  },
  {
    title: servicesContent.section6.title
  }
];

export default defineType({
  name: "services",
  title: "Services Page",
  type: "document",
  fields: [
    defineField({
      name: "headerTitle",
      title: "Header Title",
      type: "string",
      initialValue: servicesContent.title,
    }),
    defineField({
      name: "headerDescription",
      title: "Header Description",
      type: "text",
      initialValue: servicesContent.paragraph,
    }),
    defineField({
      name: "servicesList",
      title: "Services List",
      type: "array",
      initialValue: servicesList,
      of: [
        {
          name: "service",
          type: "object",
          title: "Service",
          fields: [
            defineField({
              name: "title",
              title: "Service Title",
              type: "string",
            }),
            defineField({
              name: "image",
              type: "reference",
              title: "Service Image",
              to: [{ type: "gallery" }],
            }),
            defineField({
              name: "description",
              title: "Service Description",
              type: "array",
              of: [{ type: "block" }],
            }),
          ],
        },
      ],
    }),
  ],
});
