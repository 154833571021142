import { defineField, defineType } from "sanity";

export default defineType({
    name: "lesson",
    title: "Lesson",
    type: "object",
    fields: [
        defineField({
            name: "title",
            title: "Title",
            type: "string",
            description: "Add a title for the lesson",
        }),
        defineField({
            name: "description",
            title: "Description",
            type: "text",
            description: "Add a brief description for this lesson"
        }),
        defineField({
            name: "videoLink",
            title: "Video Link",
            description: "Add a link to a video",
            type: "url",
        }),
    ],

    preview: {
        select: {
            title: 'title',
            subtitle: 'videoLink'
        },
    }
})