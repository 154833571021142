import React, { useState } from "react";
import "./services.css";
import { servicesContent } from "../../constants/data";
import { Header } from "../../components";
import { safebg } from "../../assets/images/services";
import { useScrollTo } from "../../hooks/scrollToContext";
import { useRefLinks } from "../../hooks/useRefLinks";
import { useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import {
  fadeInAnimation,
  leftColAnimation,
  rightColAnimation,
  verticalAnimation,
} from "../../utils/Animations";
import useScrollAnimation from "../../hooks/useScrollAnimation";
import sanityClient from "../../client";
import { urlFor } from "../../utils/urlFor";
import BlockContent from "@sanity/block-content-to-react";

const Services = () => {
  const [servicesData, setServicesData] = useState();
  const [loading, setLoading] = useState(true);

  const [
    staffing,
    transitionPlanning,
    treatmentPlans,
    trainingAndDevelopmentPrograms,
    translationServices,
  ] = useRefLinks();

  const { scrollToSection, currentSection } = useScrollTo();
  const [ref, controls] = useScrollAnimation();
  const [ref2, controls2] = useScrollAnimation();
  const [ref3, controls3] = useScrollAnimation();
  const [ref4, controls4] = useScrollAnimation();
  const [ref5, controls5] = useScrollAnimation();
  const [ref6, controls6] = useScrollAnimation();

  const descriptionExists = () =>
    servicesData?.hasOwnProperty("headerDescription");

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "services"]{..., servicesList[] {..., image->}}`)
      .then((data) => {
        setServicesData(data[0]);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("services data: ", servicesData);
  }, [servicesData]);

  useEffect(() => {
    switch (currentSection) {
      case "staffing":
        scrollToSection(staffing);
        break;
      case "transition-planning":
        scrollToSection(transitionPlanning);
        break;
      case "treatment-plans":
        scrollToSection(treatmentPlans);
        break;
      case "training-and-development-programs":
        scrollToSection(trainingAndDevelopmentPrograms);
        break;
      case "translation-services":
        scrollToSection(translationServices);
        break;
      default:
        break;
    }
  }, [currentSection]);

  return (
    <div id="services">
      <Header title={servicesData?.headerTitle}>
        {descriptionExists() ? (
          <p className="lead">
            {servicesData?.headerDescription || "Loading..."}
          </p>
        ) : (
          loading && <p>Loading...</p>
        )}
      </Header>

      {/* First section */}
      <section ref={staffing} className="container my-5">
        <motion.div
          ref={ref4}
          initial="hidden"
          variants={fadeInAnimation}
          animate={controls4}
          className="row"
        >
          <div className="col-12 col-md-6">
            <img
              src={urlFor(servicesData?.servicesList[0]?.image?.image)}
              alt={servicesData?.servicesList[0]?.image?.image?.alt}
              className="me-auto"
            />
          </div>
          <div className="col-12 col-md-6 mt-3 mt-md-0 pt-0">
            <h2>{servicesData?.servicesList[0]?.title}</h2>
            <BlockContent blocks={servicesData?.servicesList[0]?.description} />
          </div>
        </motion.div>
      </section>

      {/* Second section */}
      <section ref={transitionPlanning} className="container my-5">
        <div ref={ref} className="row">
          <motion.div
            initial="hidden"
            variants={leftColAnimation}
            animate={controls}
            transition={{ type: "tween", duration: 0.7 }}
            className="col-12 col-md-6 mt-3 mt-md-0 pt-0 order-2 order-md-1"
          >
            <h2>{servicesData?.servicesList[1].title}</h2>
            <BlockContent blocks={servicesData?.servicesList[1].description} />
          </motion.div>
          <motion.div
            initial="hidden"
            variants={rightColAnimation}
            animate={controls}
            transition={{ type: "tween", duration: 0.7 }}
            className="col-12 col-md-6  order-11 order-md-2"
          >
            <img
              src={urlFor(servicesData?.servicesList[1]?.image?.image)}
              className="img-fluid"
              alt={servicesData?.servicesList[1]?.image?.image?.alt}
            />
          </motion.div>
        </div>
      </section>

      {/* Third Section */}

      {/* Fourth Section : treatmentPlans */}
      <section ref={treatmentPlans} className="container my-5">
        <div ref={ref2} className="row">
          <motion.div
            initial="hidden"
            variants={leftColAnimation}
            animate={controls2}
            transition={{ type: "tween", duration: 0.5 }}
            className="col-12 col-md-6  order-11 order-md-2 my-auto"
          >
            <img
              src={urlFor(servicesData?.servicesList[2]?.image?.image)}
              className="img-fluid"
              alt={servicesData?.servicesList[2]?.image?.alt}
            />
          </motion.div>

          <motion.div
            initial="hidden"
            variants={verticalAnimation}
            animate={controls2}
            transition={{ type: "tween", duration: 0.5 }}
            className="col-12 col-md-6 mt-3 mt-md-0 pt-0 order-2 order-md-1"
          >
            <h2>{servicesData?.servicesList[2].title}</h2>
            <BlockContent blocks={servicesData?.servicesList[2].description} />

            {/* list */}
            {/* <div>
              {servicesContent.section4.list.map((item, index) => (
                <div key={index} className="d-flex align-items-start">
                  <div className="me-3">
                    <img
                      src={item.icon}
                      className="mission-icon rounded-4"
                      alt={item.title}
                    />
                  </div>
                  <div>
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div> */}
          </motion.div>
        </div>
      </section>

      {/* Fifth Section */}
      <section ref={trainingAndDevelopmentPrograms} className="container my-5">
        <div ref={ref5} className="row">
          <motion.div
            initial="hidden"
            variants={verticalAnimation}
            animate={controls5}
            transition={{ type: "tween", duration: 0.4 }}
            className="col-12 col-md-6 my-auto"
          >
            <img
              src={urlFor(servicesData?.servicesList[3]?.image?.image)}
              alt={servicesData?.servicesList[3]?.image?.alt}
              className="img-fluid"
            />
          </motion.div>
          <motion.div
            initial="hidden"
            variants={verticalAnimation}
            animate={controls5}
            transition={{ type: "tween", duration: 0.8 }}
            className="col-12 col-md-6 mt-3 mt-md-0 pt-0"
          >
            <h2>{servicesData?.servicesList[3]?.title}</h2>
            <BlockContent blocks={servicesData?.servicesList[4]?.description} />
          </motion.div>
        </div>
      </section>

      {/* Sixth Section */}
      <section ref={translationServices} className="container my-5">
        <div ref={ref6} className="row">
          <motion.div
            initial="hidden"
            variants={leftColAnimation}
            animate={controls6}
            transition={{ type: "tween", duration: 0.5 }}
            className="col-12 col-md-6 mt-3 mt-md-0 pt-0 order-2 order-md-1"
          >
            <h2>{servicesData?.servicesList[4]?.title}</h2>
            <BlockContent blocks={servicesData?.servicesList[4]?.description} />
            {/* list */}
            {/* <div>
              {servicesContent.section6.list.map((item, index) => (
                <div key={index} className="d-flex align-items-start">
                  <div className="me-3">
                    <img
                      src={item.icon}
                      className="mission-icon rounded-4"
                      alt={item.title}
                    />
                  </div>
                  <div>
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div> */}
          </motion.div>
          <motion.div
            initial="hidden"
            variants={rightColAnimation}
            animate={controls6}
            transition={{ type: "tween", duration: 0.5 }}
            className="col-12 col-md-6  order-11 order-md-2"
          >
            <img
              src={urlFor(servicesData?.servicesList[4]?.image?.image)}
              className="img-fluid"
              alt={servicesData?.servicesList[4]?.image?.alt}
            />
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Services;
