import React from "react";
import Skeleton from "react-loading-skeleton";

const InstructorSkeleton = () => {
  return (
    <div className="d-flex align-items-center gap-3 justify-content-start">
      <div className="">
        <Skeleton height={150} width={150} circle />
      </div>
      <div className="">
        <Skeleton height={40} width={280} />
        <Skeleton height={20} width={300} />

        <div style={{ marginTop: "15px" }}>
          <Skeleton
            height={20}
            width={690}
            count={3}
            style={{ marginTop: "10px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default InstructorSkeleton;
