import React from "react";
import Skeleton from "react-loading-skeleton";

const LessonItemSkeleton = ({ items }) => {
  return Array(items)
    .fill(0)
    .map((_, index) => (
      <div key={index}>
        <Skeleton height={60} />
      </div>
    ));
};

export default LessonItemSkeleton;
