import React, { useEffect, useRef, useState } from "react";
import codeOfEthicsPdf from "../../assets/Living The Code Of Ethics.pdf";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import "./pdfviewer.css";
import { throttle } from "lodash";

const PDFViewer = () => {
  const pdfWrapperRef = useRef(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [initialWidth, setInitialWidth] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const setPdfWrapperSize = () => {
    const pdfWrapper = pdfWrapperRef.current;
    console.log("pdfWrapper: ", pdfWrapper);
    if (pdfWrapper) {
      const pdfWrapperWidth = pdfWrapper?.offsetWidth;
      setInitialWidth(() => pdfWrapper?.getBoundingClientRect().width - 20);
      pdfWrapper.style.height = `100%`;
    }
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  useEffect(() => {
    window.addEventListener("resize", throttle(setPdfWrapperSize, 3000));
    setPdfWrapperSize();
    return () => {
      window.removeEventListener("resize", throttle(setPdfWrapperSize, 3000));
    };
  }, [pdfWrapperRef]);

  return (
    <div ref={pdfWrapperRef} className="container">
      <center>
        <Document
          file={codeOfEthicsPdf}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log(error)}
          className="ml-0"
        >
          <Page
            width={initialWidth}
            pageNumber={pageNumber}
            renderAnnotationLayer={true}
            renderTextLayer={true}
            renderInteractiveForms={true}
          >
            {/* next and prev buttons */}
            <div className="">
              <button
                className="btn btn-default left arrow"
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber <= 1}
              >
                <FiChevronLeft size={window.innerWidth > 768 ? 40 : 20} />
              </button>
              <button
                className="btn btn-default right arrow"
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber >= numPages}
              >
                <FiChevronRight size={window.innerWidth > 768 ? 40 : 20} />
              </button>
            </div>
          </Page>
        </Document>
        <p className="text-center">
          Page {pageNumber} of {numPages}
        </p>
      </center>
    </div>
  );
};

export default PDFViewer;
