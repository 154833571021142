import React, { useEffect, useState } from "react";
import "./employeeform.css";
import { motion } from "framer-motion";
import { employersContent } from "../../constants/data";
import { useEmail } from "../../contexts/EmailContext";

const EmployeeForm = () => {
  const initialState = {
    name: "",
    email: "",
    subject: "",
    phone: "",
    job: "",
    type: "",
    company: "",
    position_type: "",
    shift: "",
    location: "",
    duties: "",
    file: {},
  };

  const [formState, setState] = useState(initialState);
  const [error, setError] = useState(false);

  const {
    sendEmployeeEmail,
    employeeFormRef,
    setFormFields,
    setInitialState,
    statusMessage,
    setStatusMessage,
  } = useEmail();

  const handleFileChange = (event) => {
    let file = event.target.files[0];

    if (
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setState({ ...formState, file });
      setError(false);
      setStatusMessage("");
    } else {
      event.target.value = null;
      setState({ ...formState, file: null });
      setError(true);
      setStatusMessage(
        "Invalid file type. Only PDF and Word documents are allowed."
      );
    }
  };

  const handlePositionTypeChange = (event) => {
    let position_type = event.target.value;
    if (position_type !== "select") {
      setState({ ...formState, position_type });
      setError(false);
      setStatusMessage("");
    } else {
      event.target.value = null;
      setState({ ...formState, position_type: null });
      setError(true);
      setStatusMessage("Please select a position type.");
    }
  };

  useEffect(() => {
    // console.log("our Story: ", ourStory);
    setFormFields(formState);
    setInitialState(initialState);
    setStatusMessage("");
  }, []);

  return (
    <div id="employeeForm">
      <motion.section
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "tween", duration: 0.7 }}
        className="container form-section"
      >
        <div className="row mx-auto">
          <div className="col-12 form-container">
            <form
              className="rounded-4 shadow-lg d-flex flex-column justify-content-center px-4 py-5 px-md-5"
              ref={employeeFormRef}
              onSubmit={sendEmployeeEmail}
            >
              <div className="d-flex flex-column justify-content-center">
                <h4 className="text-center">Request an employee</h4>
                {statusMessage && (
                  <p
                    className={`alertMsg mb-2 text-center ${
                      error ? "text-danger" : "text-success"
                    }`}
                  >
                    {statusMessage}
                  </p>
                )}
                <p className="text-center mb-0">
                  Are you looking for an outstanding staff?
                </p>
                <p className="text-center">
                  Please complete the form below or contact us directly:
                </p>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <h6 className="fw-bold mb-4">Job Information</h6>

                  {/* job title */}
                  <div className="mb-4 form-group">
                    <label htmlFor="jobTitle" className="fw-bold">
                      Job Title *
                    </label>
                    <input
                      id="jobTitle"
                      type="text"
                      placeholder="job title"
                      name="job"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                    <input
                      type="hidden"
                      name="subject"
                      value="New Employee Request"
                    />
                    <input type="hidden" name="type" value="employee" />
                  </div>

                  {/* company */}
                  <div className="mb-4 form-group">
                    <label htmlFor="company" className="fw-bold">
                      Company *
                    </label>
                    <input
                      id="company"
                      type="text"
                      name="company"
                      placeholder="you company name here"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>

                  {/* position type */}
                  <div className="mb-4 form-group">
                    <label htmlFor="positionType" className="fw-bold">
                      Is this position temporary or full-time?
                    </label>
                    <select
                      id="positionType"
                      name="position_type"
                      className="form-select mt-2 py-3 px-4 rounded-4"
                      aria-label="Default select example"
                      onChange={handlePositionTypeChange}
                      required
                    >
                      <option>select</option>
                      {employersContent.positionTypes.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* shift */}
                  <div className="mb-4 form-group">
                    <label htmlFor="shift" className="fw-bold">
                      Shift *
                    </label>
                    <input
                      id="shift"
                      type="text"
                      name="shift"
                      placeholder="your shift here"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>

                  {/* location */}
                  <div className="mb-4 form-group">
                    <label htmlFor="location" className="fw-bold">
                      Location *
                    </label>
                    <input
                      id="location"
                      type="text"
                      name="location"
                      placeholder="your location here"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>
                </div>
                {/* second column */}
                <div className="col-12 col-md-6">
                  <h6 className="fw-bold mb-4">Your Contact Information</h6>

                  {/* email */}
                  <div className="mb-4 form-group">
                    <label htmlFor="email" className="fw-bold">
                      Email *
                    </label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="example@yourmail.com"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>

                  {/* full name */}
                  <div className="mb-4 form-group">
                    <label htmlFor="fullName" className="fw-bold">
                      Full Name *
                    </label>
                    <input
                      id="fullName"
                      type="text"
                      name="name"
                      placeholder="john doe"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>

                  {/* phone number */}
                  <div className="mb-4 form-group">
                    <label htmlFor="phoneNumber" className="fw-bold">
                      Phone Number *
                    </label>
                    <input
                      id="phoneNumber"
                      type="tel"
                      name="phone"
                      placeholder="+1 234 8583 923"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      required
                    />
                  </div>

                  {/* upload job descrtiption */}
                  <div className="mb-4 form-group">
                    <label htmlFor="jobFile" className="fw-bold">
                      Upload Your job Description *
                    </label>
                    <input
                      id="jobFile"
                      type="file"
                      name="file"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                      onChange={handleFileChange}
                      required
                    />
                  </div>
                </div>
                {/* duties and responsibilities */}
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="duties" className="fw-bold">
                      Duties & Responsibilities *
                    </label>
                    <textarea
                      name="duties"
                      id="duties"
                      cols="30"
                      rows="8"
                      className="form-control rounded-4 mt-2 py-3 px-4"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="my-4">
                <input
                  type="submit"
                  value="SEND"
                  className="btn py-3 px-5 btn-default rounded-5 form-btn-dark fw-bold"
                ></input>
              </div>
            </form>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default EmployeeForm;
