import React from "react";
import "./staffing.css";

const Staffing = () => {
  return (
    <>
      <div>Staffing</div>
    </>
  );
};

export default Staffing;
