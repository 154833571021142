import { AiOutlineHome } from "react-icons/ai"
import { HiOutlineInformationCircle, HiOutlineUsers, HiOutlineDocumentDuplicate } from "react-icons/hi"
import { GrServices, GrUserWorker, GrDocumentConfig } from "react-icons/gr"
import { GiClassicalKnowledge } from "react-icons/gi"
import { MdMiscellaneousServices } from "react-icons/md"
import { FaRegRegistered } from "react-icons/fa"
import { AiOutlinePullRequest } from "react-icons/ai"
import { RiContactsBook2Line } from "react-icons/ri"
import { FiSettings } from "react-icons/fi"

export const deskStructure = (S) =>
    S.list()
        .title('Content')
        .items([

            S.listItem()
                .title('Home Page')
                .icon(AiOutlineHome)
                .child(
                    S.editor()
                        .schemaType('home')
                        .documentId('74e88acc-4693-4879-a3fc-a69f69823900')
                ),

            S.listItem()
                .title('About Page')
                .icon(HiOutlineInformationCircle)
                .child(
                    S.editor()
                        .schemaType('about')
                        .documentId('bd5114aa-2184-4ff2-81ee-903a5d727be1')
                ),

            S.listItem()
                .title('Services Page')
                .icon(MdMiscellaneousServices)
                .child(
                    S.editor()
                        .schemaType('services')
                        .documentId('1c3d39b4-08b4-4011-be82-7583d51eb3df')
                ),

            S.listItem()
                .title('Employers Page')
                .icon(HiOutlineUsers)
                .child(
                    S.editor()
                        .schemaType('employers')
                        .documentId('dc39606a-f3d9-46b5-bd1e-144875792777')
                ),

            S.listItem()
                .title('Courses Page')
                .icon(GiClassicalKnowledge)
                .child(
                    S.editor()
                        .schemaType('courses')
                        .documentId('64660de1-0649-4e9e-970a-0cfc3821630b')
                ),

            S.listItem()
                .title('Register Course Page')
                .icon(FaRegRegistered)
                .child(
                    S.editor()
                        .schemaType('registerCourse')
                        .documentId('registerCoursePage')
                ),

            S.listItem()
                .title('Request For Service Page')
                .icon(AiOutlinePullRequest)
                .child(
                    S.editor()
                        .schemaType('requestForService')
                        .documentId('requestForServicePage')
                ),

            S.listItem()
                .title('Submit Resume Page')
                .icon(HiOutlineDocumentDuplicate)
                .child(
                    S.editor()
                        .schemaType('submitResume')
                        .documentId('submitResumePage')
                ),

            S.listItem()
                .title('Contact Page')
                .icon(RiContactsBook2Line)
                .child(
                    S.editor()
                        .schemaType('contact')
                        .documentId('contactPage')
                ),

            S.listItem()
                .title('Site Settings')
                .icon(FiSettings)
                .child(
                    S.editor()
                        .schemaType('siteSettings')
                        .documentId('siteSettingsPage')
                ),

            // Add a visual divider (optional)
            S.divider(),

            // List out the rest of the document types, but filter out the config type
            ...S.documentTypeListItems()
                .filter(listItem => !['courses', 'home', 'about', 'services', 'employers', 'registerCourse', 'requestForService', 'submitResume', 'contact', 'siteSettings'].includes(listItem.getId()))
        ])