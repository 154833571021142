import React from "react";
import "./loadingspinner.css";

const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
      <div className="d-inline mt-2 text-center">Loading Sliders...</div>
    </div>
  );
};

export default LoadingSpinner;
